﻿.skip-link {
    position: absolute;
    top: 0;
    left: 0;
}

.skip-link__link {
    opacity: 0;
    pointer-events: none;
}

.skip-link__link:focus {
    opacity: 1;
    display: block;
    pointer-events: all;
}

.skip-link {
    position: absolute;
    top: 0;
    left: 0;
}

.skip-link__link {
    opacity: 0;
    pointer-events: none;
}

.skip-link__link:focus {
    opacity: 1;
    display: block;
    pointer-events: all;
}

.js-hidden {
}

.stuck {
    @media only screen and (min-width: 1160px) { 
        position: fixed;
        top: 0;
        left: 0;
        transition: transform 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
        box-shadow: 0 0 6px rgba(0,0,0,0.10);  
    }
}
/*
.menu-is-open {
    .stuck {
        position: static;
        top: auto; 
        left: auto;
    }

}
    */
.sticky-spacer {
    display: none;
    background: $darker-gray;

    /*@media only screen and (min-width: 1160px) {
        float: left;
        width: 100%;
        display: block;
    }*/
}

.sticky-spacer.js-hidden {
    display: none;
}

.nav {
    padding-bottom: 15px;
}

@media #{$large-up} {
    $menu-animation-time: 500ms;

    .fixed-menu .site-header {
        // This height is being set by javascript.
        .relative.nav-wrapper {
            top: 40px;
        }
    }

    .fixed-menu .main-menu-wrapper {
        position: relative;
    }

    .ms-menu {
        transform: translateY(0);
        transition: transform $menu-animation-time cubic-bezier(0.165, 0.84, 0.44, 1);
        position: relative;
        z-index: 500;
        background-color: #FFF;


        @media #{$large-up} {
            transform: none;
        }
    }

    .fixed-menu .ms-menu {
        position: fixed;
        bottom: 100%;
        left: 0;
        width: 100%;
        z-index: 500;
        transform: translateY(100%);
        transition: transform $menu-animation-time cubic-bezier(0.165, 0.84, 0.44, 1);
        box-shadow: 0 0 6px rgba(0,0,0,0.25);

        @media #{$large-up} {
            transform: none;
        }
    }

    .secondary-nav, .primary-nav {
        opacity: 1;
        transform: translateY(0%);
        transition: all $menu-animation-time cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .fixed-menu .secondary-nav {
        display: none;
        transform: translateY(-100%);
    }

    .primary-nav {
        transition: all $menu-animation-time cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .fixed-menu .primary-nav {
        transform: translateY(-50%);
    }
}



@keyframes ANIMATE-MENU-IN {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

.menu-button {
    display: block;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 54px;
    height: 54px;
    font-size: 0;
    text-indent: -9999px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    -webkit-transition: background .3s;
    transition: background .3s;
    display: inline-block;
    cursor: pointer;
    float: right;
    background-color: transparent !important;


    span {
        display: block;
        position: absolute;
        top: 26px;
        left: 10px;
        right: 10px;
        height: 3px;
        background: $white;
        pointer-events: none;
    }

    span::after, span::before {
        position: absolute;
        display: block;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: $white;
        content: "";
    }

    span::before {
        top: -10px;
    }

    span::after {
        bottom: -10px;
    }

    span::before {
        -webkit-transition-property: top,-webkit-transform;
        transition-property: top,transform;
    }

    span::after, span::before {
        -webkit-transition-duration: .3s,.3s;
        transition-duration: .3s,.3s;
        -webkit-transition-delay: .3s,0s;
        transition-delay: .3s,0s;
    }

    span::after {
        -webkit-transition-property: bottom,-webkit-transform;
        transition-property: bottom,transform;
    }
}

.menu-is-open .menu-button {
    span {
        background: 0 0;
    }

    span::after, span::before {
        -webkit-transition-delay: 0s,.3s;
        transition-delay: 0s,.3s;
        background: #fff;
    }

    span::before {
        top: 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        background: #fff;
    }

    span::after {
        bottom: 0;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    span::after {
        -webkit-transition-property: bottom,-webkit-transform;
        transition-property: bottom,transform;
    }
}

.site-logo {
    width: 100%;
    z-index: 5;

    svg {
        max-width: 100%;
        height: 60px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

.secondary-nav {
    margin-bottom: 10px;
}

@media only screen and (min-width: 1160px) {


    .primary-nav {
        a {
            text-decoration: none;
            color: $black;
            transition: color 300ms;
            font-size: 20px;
            font-weight: 600;
        }

        .active::after,
        li:focus::after,
        li:hover::after, {
            height: 2px;
            opacity: 1;
            -webkit-transform: translateY(0px);
            -moz-transform: translateY(0px);
            transform: translateY(0px);
        }



        li::after {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 1px;
            background: black;
            content: '';
            opacity: 0;
            -webkit-transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
            -moz-transition: height 0.3s, opacity 0.3s, -moz-transform 0.3s;
            transition: height 0.3s, opacity 0.3s, transform 0.3s;
            -webkit-transform: translateY(-10px);
            -moz-transform: translateY(-10px);
            transform: translateY(-10px);
        }

        &:hover {
            color: white;
            transition: all 500ms ease;
        }
    }



    .nav {
        height: auto;
        display: block;
        visibility: visible;
        transition: all 500ms cubic-bezier(0, 0, 0.39, 1.1);
        padding-top: 15px;
        @include outer-container(100%);

        .ms-menu--is-open & {
            transform: translateY(-100%);
        }

        .main-nav-item.expanded:hover .level2 {
            display: block;
            height: auto;
            max-height: 500px;
            padding: $tiny-padding;
        }

        .level2 {
            transition: all 500ms ease;
            position: absolute;
            width: 100%;
            z-index: 999;
            background: #fff;
            text-align: left;
            list-style: square;
            top: 37px;
            min-width: 200px;
            padding: 0 $tiny-padding;
            max-height: 0;
            overflow: hidden;

            .nav__item {
                padding: 0;
                display: block;
                font-size: 0.9em;
                padding: 7px 0;

                a {
                    &:hover {
                        color: $dark-gray;
                    }
                }
            }
        }


        @media #{$large-up} {
            transition: none;
           

            .ms-menu--is-open & {
                transform: none;
            }
        }

        &__list {
            display: block;



            @media #{$large-up} {
                display: block;
                margin: 0 auto;
                text-align: center;
            }
        }

        &__item {
            display: block;
            transition: all 500ms ease;
            transform: translateY(100px);
            opacity: 0;
            margin: 0 19px;
            padding: $tiny-padding 0;
            line-height: 0.85em;

            &:last-child {
                border-right: 0;
            }

            @media #{$large-up} {
                transform: translate(0);
                opacity: 1;
                transition: none;
                display: inline-block;
                position: relative;
                z-index: 999;
            }
        }
    }
}

.menu-button-wrapper {
    opacity: 0;
    position: absolute;
    right: 23px;
    z-index: 999;
    margin-top: 20px;
    background: rgba(0, 0, 0, 0.30);
    top: 0;
}
//MOBILE MENU
@media only screen and (max-width: 1159px) {
    .ms-menu {
        position: static;
    }

    .primary-nav .nav-logo {
        background-image: url('../Svgs/logo.svg');
        background-repeat: no-repeat;
        position: absolute;
        left: 50%;
        right: 50%;
        margin-left: -55px;
        width: 110px;
        height: 60px;
        top: auto;
        bottom: -80px;
    }

    .site-logo {
        width: 80%;
        z-index: 5;
        float: left;
    }

    .site-logo svg {
        max-width: 200px;
        height: 65px;
        margin-top: 15px;
        margin-bottom: 5px;
        margin-left: 15px;
    }

    .menu-button-wrapper {
        opacity: 1;
    }



    .header-section {
        position: fixed;
        right: 100%;
        top: 0px;
        padding-bottom: 140px;
        width: 100%;
        z-index: 99;
        background-color: $red;
        //background: linear-gradient(40deg, #16222A 10%, #3A6073 90%); /* W3C */
        background-size: 400% 400%;
        //animation: bgAnimation 16s ease infinite;
        transition: all 600ms cubic-bezier(0.63, 0.18, 0.2, 0.93);
    }

    .secondary-nav {
        padding-top: 50px;
    }

    .nav-wrapper {
        height: 100%;
    }

    span.right-logo {
        display: none;

        img {
            max-width: 110px;
            width: 100%;
        }
    }

    .nav {
        width: 100%;
        background-color: inherit;
        float: left;
        color: $black;
        text-align: center;

        &__item {
            opacity: 1;

            a {
                color: #fff;
            }

            a:hover {
                color: $dark-gray;
            }
        }

        &__list {
        }
    }

    .top-navigation {
        padding: $small-padding;

        ul li {
            padding: 5px 0;
            font-size: 1em;
        }
    }

    .header-section .menu li a {
        text-decoration: none;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
    }

    .header-section .menu li a:hover {
        text-decoration: none;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
    }

    .toggle-submenu {
        height: 21px;
        width: 25px;
        display: inline-block;
        vertical-align: top;
        margin-left: 12px;
        position: absolute;
        right: 1em;
        cursor: pointer;
    }

    .toggle-submenu:after, .toggle-submenu:before {
        text-align: center;
        height: 3px;
        background-color: $red;
        width: 100%;
        font-size: 30px;
        content: "";
        display: inline-block;
        vertical-align: top;
        transition: all 300ms ease;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .toggle-submenu:before {
        transform: translateY(-50%) rotate(90deg);
    }

    .toggle-submenu.nav__item--show-children:after {
    }

    .toggle-submenu.nav__item--show-children:before {
        transform: translateY(-50%) rotate(0deg);
    }

    .nav__item--has-children {
        max-height: 0;
        transition: all 300ms ease;
        overflow: hidden;

        .nav__item--show-children ~ & {
            max-height: 600px;
        }
    }



    .site-logo {
        width: 100%;
        z-index: 5;
    }

    .menu-hr {
        display: block;
    }




    .primary-nav {
        padding: $small-padding;

        ul li {
            padding: 5px 0;
            text-transform: uppercase;
            font-size: 1.2em;
        }
    }

    .nav__item:nth-child(4) {
        margin-right: 0px !important;
    }

    .nav__item:nth-child(5) {
        margin-left: 0px !important;
    }

    .nav-logo {
        margin-top: 30px !important;
    }

    .header-section {
        position: absolute;
        top: 0px;
    }

    .menu-is-open {

        .header-section {
            position: absolute;
            color: black;
            background: $red;
            -webkit-transform: translateX(100%);
            -ms-transform: translateX(100%);
            transform: translateX(100%);
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
            padding-top: 80px;

            a {
                color: black;
            }
        }
    }
}

.menu-btn {
    float: left;
    width: 20%;
    margin-left: 2%;
}

/*.nav__item:nth-child(3) {
    margin-right: 150px;
}

.nav__item:nth-child(4) {
    
}*/
li.item1.nav__item.main-nav-item {
    //margin-left:60px;
}

.header-section {
    /*box-shadow: 0 0 6px rgba(0,0,0,0.4);*/
    background: $red;
}
.header-section.stuck {
    box-shadow: 0 0 6px rgba(0,0,0,0.4);
    background: $red;

    a {
    }
}

.nav-logo { 
    background-image: url('../Svgs/logo.svg');  
    background-repeat: no-repeat; 
    position: absolute;
    left: 0;
    width: 144px;
    height: 40px;
    top: 10px;
}