﻿body {
    background: $darker-gray;

    @media only screen and (min-width: 1345px) {
        background: $darker-gray;
    }
}

.main-content-max-width, .photoMosaic {
    max-width: 110em;
    margin: 0 auto;
}

.block {
    display: block;
}

.center-block.column {
    margin: 0 auto;
    float: none;
     
}

.page-wrapper-inner {
    background: $darker-gray;
    overflow: hidden;
}

.inner-wrapper {
    margin: 0 auto;
    float: none;
    width: 74.41059%;
    overflow: hidden;

    @media #{$small-only} {
        width: 100%;
    }
} 

.no-margin {
    margin: 0 !important;
}

.img-fill-box img {
    width: 100%;
    display: block;
}

.padding {
    padding: $large-padding 0; 
    border-top: 1px solid $dark-gray !important;

    @media #{$small-only} {
        padding: $medium-padding 0;
    }
}

.padding-top {
    padding-top: 6em;

    @media #{$small-only} {
        padding-top: $medium-padding;
    }
}

.extra-padding-top {
    padding-top: 9em;

    @media #{$small-only} {
        padding-top: $medium-padding;
    }
}

.medium-padding {
    padding: $medium-padding;

    @media #{$small-only} {
        padding: $small-padding;
    }
}

.large-margin-top {
    margin-top: $large-padding !important;
}

.medium-margin {
    margin: $medium-padding;

    @media #{$small-only} {
        margin: $small-padding;
    }
}

.large-padding {
    padding: $large-padding;

    @media #{$small-only} {
        padding: $medium-padding $small-padding;
    }
}

.small-padding {
    padding: $small-padding;
}

.tiny-padding {
    padding: $tiny-padding;
}

.small-padding-bottom {
    padding-bottom: $small-padding;
}

.small-margin-bottom {
    margin-bottom: $small-padding;
}

.small-margin-top {
    margin-top: $small-padding;
}

.medium-padding-bottom {
    padding-bottom: $medium-padding;
}

.large-padding-bottom {
    padding-bottom: $large-padding;
}

.large-padding-top {
    padding-top: $large-padding;
} 

.padding-bottom {
    padding-bottom: 6em;

    @media #{$small-only} {
        padding-bottom: 1em; 
    }
}

.full-width {
    min-width: 100%;
}

.illustration-picture {
    background: $darker-gray;
    border-bottom: 1px solid $darker-gray; 
}

section {
    position: relative;  
}

.min-height {
    min-height: 800px;

    @media #{$medium-only} {
        min-height: 650px;
    }

    @media #{$small-only} {
        min-height: 500px;
    }
}

.quote-img {
    min-height: 300px;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

.bottom-left-to-right-diagonal {
    &:after {
        top: auto;
        bottom: 56px;
    }
}

section::before, section::after {
    position: absolute;
    content: '';
    pointer-events: none;
}


.white-bg {
    background: $darker-gray;
    border: 1px solid $darker-gray;
}

.green-bg {
    background: $yellow;
    color: $black;
} 
 
.prospect {
    padding: 2em 9em;
    margin-bottom: 20em !important;
}

.prospect-banner {
    opacity: 0.95;
}

.floorplan-outer .image {
}

#max-bygget-prospect-logo {
    width: 200px
}

.blue-bg {
    background: #304344;
    color: #ffffff;
    opacity: 0.84;

    h2, h3, h4, h5, h6,
    .h2, .h3, .h4, .h5, .h6 {
        color: #ffffff;
    }

    h1, .h1 {
        color: $red;
    }
}

.transparent-white-bg {
    background: $darker-gray;
}

.dark-gray-bg {
    background: $dark-gray;
    color: #ffffff;

    h1, h2, h3, h4, h5, h6 {
        color: #ffffff;
    }
}


.white {
    color: $darker-gray !important;
}

.clear {
    clear: both;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.overflow {
    overflow: auto;
}

.align-center {
    text-align: center;
}

// MAX-BYGGET - styling
@import "https://cdn.jsdelivr.net/npm/animate.css@3.5.1";
.max-blue {
    fill: #859daf;
}
.max-red {
    fill: #ce4512;
}
.max-yellow {
    fill: #f4b211;
}



.aspect-16-9 {
    padding-top:0;
    height:350px;
    overflow:hidden;
    @media #{$medium-up} {
        padding-top:56.25%;
    }
    @media #{$xlarge-up} {
        padding-top:0;
        height:800px; 
    }
}

.top-image-wrapper,
.top-video-nor-wrapper,
.top-video-eng-wrapper {
    position:absolute;
    top:0;
    left:50%; 
    right:0;
    bottom:0;
    min-width:640px; 
    transform:translateX(-50%);
    overflow:hidden;
    transition:opacity 1.5s ease-out, visibility 1.5s ease, z-index 0s linear 1.5s;
    opacity:1;
    visibility:visible; 
    @media #{$medium-up} {  
        left:0;
        transform:none;
    }
    .is-playing-nor-video &,
    .is-playing-eng-video & { 
        transition:opacity 1.5s ease-out, visibility 1.5s ease, z-index 0s linear;
    }
    > video, 
    > img {
        position:absolute; 
        display:block;
        width:100%; 
        top:0;
        left:0;
        right:0;
        bottom:0;
        @media #{$xlarge-up} {
            top:50%;
            transform:translateY(-50%); 
        }
    } 
}

.is-playing-nor-video,
.is-playing-eng-video {
    .top-image-wrapper {
        opacity:0;
        visibility:hidden;
    }
}

.top-image-overlays {
    position: absolute;
    z-index: 6;
    top: 24px;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;

    @media #{$medium-up} {
        top: 2vw;
    }

    #max-animated-logo {
        // KLP styling
        width: 35%;

        @media #{$medium-up} {
            width: 25%;
        }

        @media #{$large-up} {
            width: 15%;
        }

        float: left;
        padding-left: 30px;
        // Lysgarden styling
        display: block;
        max-width: 800px;
        transition: opacity .5s ease, transform .5s ease, visibility .5s ease;

        .is-playing-nor-video &,
        .is-playing-eng-video & {
            opacity: 0;
            visibility: hidden;
            transform: translateY(-20px);
        }
    }

    .main-controls {
        display: block;
        position: relative;
        margin-top: 250px;
        text-align: center;
        opacity: 1;
        visibility: visible;
        transition: opacity .7s ease, transform .7s ease, visibility .7s ease;

        @media #{$medium-up} {
            margin-top: 25vw;
            // 5vw for Lysgarden
        }

        @media #{$xlarge-up} {
            margin-top: 15vw;
            // 5vw for Lysgarden
        }

        .is-playing-nor-video &,
        .is-playing-eng-video & {
            opacity: 0;
            visibility: hidden;
            transform: translateY(-20px);
        }

        > div {
            display: inline-block;
            background: rgba(#000, .7);
            padding: 7px 12px 7px 58px;
            height: 50px;
            text-align: left;
            cursor: pointer;
            border-radius: 3px;
            position: relative;

            &:first-child {
                margin-right: 10px;
            }

            svg {
                display: block;
                position: absolute;
                width: 36px;
                height: 36px;
                top: 7px;
                left: 12px;
            }

            i {
                position: relative;
                display: block;
                top: 50%;
                transform: translateY(-50%);
                font-style: normal;
                color: $white;
            }

            .svg-fill {
                fill: $red;
                transition: all .5s ease;
            }

            &:hover {
                .svg-fill {
                    fill: $white;
                }
            }
        }
    }

    .controls {
        position: absolute;
        right: 23px;
        bottom: 23px;
        width: 49px;
        height: 49px;
        opacity: 0;
        visibility: hidden;
        transition: opacity .5s ease;
    }

    #pause-button {
        display: block;
        cursor: pointer;

        svg {
            width: 49px;
            height: 49px;
        }

        .svg-fill {
            fill: $white;
            transition: all .5s ease;
        }

        &:hover {
            .svg-fill {
                fill: $red;
            }
        }
    }

    .is-playing-nor-video &,
    .is-playing-eng-video & {
        .controls {
            opacity: 1;
            visibility: visible;
        }

        .main-controls {
            opacity: 0;
            visibility: hidden;
        }
    }
}

.typography {
    img,
    video {
        width: 100%;
    }

    p {
        font-size: 18px;
        padding-bottom: 15px;

        @media #{$medium-up} { 
            font-size: 22px; 
        }
    }
}

.typography p img {
    width: 100% !important;
    height: auto !important;
    padding-top: 5px;
    padding-bottom: 5px;
    max-height: 750px;
    object-fit: cover;

    @media #{$medium-up} {
        max-height: 650px;
    }
}

.hidden-content {
    position: relative;
    overflow: hidden;
    max-height: 100px;
    transition: max-height .5s ease-out;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
        opacity: 1;
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, #222222 0.8);
        transition: all .5s ease-out;
        pointer-events: none;
    }

    &.open {
        max-height: 3200px;
        transition: max-height .5s ease-in;

        &:before {
            opacity: 0;
            z-index: -1;
        }
    }
}



.top-image-wrapper {
    z-index:5;
}

.top-video-nor-wrapper,
.top-video-eng-wrapper {
    z-index:3;
}

.is-playing-nor-video {
    .top-video-nor-wrapper {
        z-index:4;
    }
}
.is-playing-eng-video {
    .top-video-eng-wrapper {
        z-index:4;
    }
}



.text-overlay-top {
    position: absolute;
    top: 35%;
    left: 5%;

    &h1, &.h1 {
        color: #fff;
        letter-spacing: 2px;
        background: rgba(56, 135, 121, 0.79);
        box-shadow: 0px 0px 108px 47px rgba(56, 135, 121, 0.85);
    }
}

.video-control {
    right: 20px;
    bottom: 20px;
    z-index: 999;
}

.video-wrapper {
    video, img {
        width: 100%;
        display: block;
    }
}

.video-wrapper-inner {
   
    margin: 0 auto;
    margin-bottom:60px;
    position:relative;
     @media #{$large-up} {
     max-width: 65.88078%;
     }
    img { 
        display: block;
    }
}

.logo-wrapper-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;

    .video-logo {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -80px;
        margin-top: -40PX;

        @media #{$small-only} {
            margin-left: -60px;
            margin-top: -30px;
        }

        img {
            width: 100%;
        }
    }
}

.logo-wrapper-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;

    .picture-top-logo {
        position: absolute;
        top: 30%;
        left: 50%;
        margin-left: -400px;
        margin-top: -100px;

        @media #{$small-only} {
            margin-left: 0;
            margin-top: 0;
            transform: translate(-50%, -50%);
        }

        @media #{$medium-only} {
            margin-left: -300px;
            margin-top: -50px;
        }

        img {
            width: 100%;

            @media #{$large-up} {
                min-width: 800px;
                max-width: 100%;
            }

            @media #{$medium-only} {
                min-width: 600px;
                max-width: 100%;
            }

            @media #{$small-only} {
                max-width: none;
                width: 88vw;
            }
        }
    }
}

.typography .editorcontent {
    h2 {
        font-size: 2.48832rem;
        font-weight: 100;
        text-align: center;


        @media #{$large-up} {
            font-size: 4.29982rem !important;
            line-height: 1.2em !important;
        }
    }
}

.partner-logo {
    display: inline-block;
    padding-right: 30px;
}

.poster-img {
    display: block;
}

.mobile-logo {
    max-width: 120px;
}

video {
    max-width: 100%;
}

.fill-image {
    margin-top: 0;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    position: relative;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; 
    width: 100%;
    height: 100%; 
}

.kunstner-bilde-wrapper {
}

#kunstner-bilde {
    width: 100%
}

    .logo-img-wrapper {
        display: inline-block;
    }

    .product-page {
        .product__img {
            width: 100%;
            display: block;
        }
    }


.gallery-outer {
    position: relative;
    overflow: hidden;
    width: 100%;
    float: left;
    display: block;
    text-decoration: none;

    @media #{$small-only} {
        margin: 0 auto;
        float: none;
        margin-bottom: $tiny-margin;
        width: 100%;
        padding: 0px;
        border: none;
    }

    @media #{$medium-only} {
        margin-bottom: 2.35765%;

        &:nth-child(2n) {
            margin-right: 0;
        }
    }

    @media #{$medium-up} {
        .img-wrapper {
            padding: 20px 40px;
            border: 1px solid #16232c;
        }
    }
}

    .floorplan-page-wrapper {
        background: $darker-gray;
        border: 1px solid $darker-gray;
    }

    .floor-image-wrapper {
        margin-top: px;
    }

    .floorplan-image {
        width: 100%;
    }

.floorplan-content {
    max-width: 800px;
    margin: 0 auto;  
}

    .gallery-inner {
        @media #{$medium-up} {
            position: absolute;
            bottom: 20px;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto !important;
        }
    }

    .gallery-inner {
        @media #{$medium-up} {
            transition: ease all 500ms;
            overflow: hidden;
            transform: translateY(200%);
        }

        .hidden {
            @media #{$small-only} {
                display: none;
            }
        }
    }

    .gallery-outer:hover .gallery-inner {
        @media #{$medium-up} {
            transform: translateY(0);
        }
    }
    //FOOTER
    .tripadvisor {
        top: 20%;
        right: 0;

        @media #{$small-only} {
            position: static;
            margin: 0 auto;
        }

        #CDSWIDCOE {
            @media #{$small-only} {
                margin: 0 auto;
            }
        }
    }

    #map {
        position: relative;
        padding-bottom: 35%; // This is the aspect ratio
        height: 550px;
        overflow: hidden;

        @media #{$small-only} {
            height: 330px;
        }
    }

    #map iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }

    .footer-icons {
        .footer-item {
            float: left;
            margin: 0;
            padding: 0;
            border-left: 2px solid $white;
            border-top: 2px solid $white;
            padding: 12px 0;
            width: 33.333%;
            min-height: 75px;

            @media #{$medium-up} {
                width: 12.5%;

                &:first-child {
                    border-left: none;
                }
            }

            @media #{$small-only} {
                &:nth-child(3n) {
                    border-right: 2px solid $white;
                }

                &:nth-child(6) {
                    border-bottom: 2px solid $white;
                }

                &:last-child {
                    border-right: 2px solid $white;
                }
            }
        }

        a.footer-item:hover img {
            opacity: 0.5;
        }

        img {
            display: block;
            width: 90%;
            margin: 0 auto;
            max-height: 40px;
        }
    }

    .events {
        @media #{$small-only} {
            padding-bottom: $medium-padding;
        }
    }

    .event-item {
        @media #{$small-only} {
            padding-bottom: $medium-padding;
        }
    }

    img {
        max-width: 100%;
    }

    video {
        max-width: 100%;
    }

    #VideoPlayControl {
        cursor: pointer;

        svg {
            width: 35px;
            opacity: 0.7;
        }
    }

    #VideoPauseControl {
        cursor: pointer;

        svg {
            width: 35px;
            opacity: 0.7;
        }
    }




    .facebook-icon {
        content: "";
        display: block;
        width: 55px;
        height: 55px;
        margin: 0 auto;
        background-image: url("../Svgs/fb.svg");
        background-repeat: no-repeat;
        transition: all 0.5s ease;
        margin-top: $tiny-margin;

        &:hover {
            opacity: 0.4;
        }
    }

    .to-top {
        border-radius: 50%;
        background-color: $yellow;
        width: 50px;
        height: 50px;
        padding: 2px 6px;
        margin: 0;
        color: #fff;
        opacity: 0.5;
        position: fixed;
        bottom: 10px;
        right: 40px;
        text-decoration: none;
        -moz-transition: opacity ease-in 0.2s;
        -o-transition: opacity ease-in 0.2s;
        -webkit-transition: opacity ease-in 0.2s;
        transition: opacity ease-in 0.2s;
        background-image: url('../Icons/arrow-up.svg');
        background-size: 30px 30px;
        background-position: center;
        background-repeat: no-repeat;
        text-indent: -9999px;
        z-index: 99;

        &:hover {
            opacity: 1;
            color: #FFF;
        }
    }

    .floorplan-single {
        text-align: center;

        > a {
            float: initial !important;
            margin-left: auto !important;
            margin-right: auto !important;
        }
    }  

//Floorplan Page
.floorplan-page {
    div {
        text-align: center !important;
    }

    table {
        margin: 0 auto !important;
        margin-top: 20px !important;
    }

    .typography p {
        padding-left: 50px;
        padding-right: 50px;
    }
}

.typography h3 {

    @media only screen and (max-width: 40em) {
        font-size: 1.44rem;
        line-height: 1.1em;
    }

    font-size: 2.48832rem;
    line-height: 1.1em;
}

.typography div {
    font-size: 18px;

    @media only screen and (min-width: 40.0625em) {
        font-size: 22px;
    }
}

.beliggenhet-text {
    font-size: 18px;

    @media only screen and (min-width: 40.0625em) {
        font-size: 22px;
    }
}

//Forside: News

.news-section-wrapper {
    overflow: auto;
}

.news-image-link:hover {
    opacity: 0.85; 
}

.nyheter-header {
}

.news-front-page-item {

    @media #{$small-only} {
        text-align: center;
    }

    padding-bottom: 20px;

    @media #{$large-up} {
        width: 31% !important;
    }
}

.portal-news-page-date-wrapper {
    position: absolute;
    text-transform: uppercase;
    background: #F2B231;
    width: 55px;
    height: 55px;
    color: white;
    padding: 5px; 
    z-index: 1;
    text-align: center;
    left: 0%;
}
.news-image-link {
    width: 100%;
    transition: 300ms all ease;
}
.portal-news-page-header {
    padding-top: 6px;
    padding-bottom: 8px;
}
.portal-news-page-description {
    text-align: left !important;
}
.news-content-read-more {
    padding: 20px;
    font-size: 18px;
     
    @media only screen and (min-width: 40.0625em) {
        font-size: 22px;
    }
}

//News Folder
.news-folder-header {
    padding-top: 15px;
}
.page-folder-news-item {
    padding-bottom: 25px;
    padding-top: 25px;



    @media #{$medium-up} {
        width: 100%;
    }
}
.img-fullwidth {
    width: 100%;
}
.news-folder-content {
    @media #{$large-up} {
        width: 95%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.news-folder-main-news-date-wrapper-wrapper {
    position: relative;
}


.news-folder-main-news-date-wrapper {
    position: absolute;
    text-transform: uppercase;
    background: $yellow;
    width: 55px;
    height: 55px;
    color: white;
    padding: 5px;
    z-index: 1;
    text-align: center;
    left: 20px;
    bottom: -2px;
}

.news-folder-sub-news-date-wrapper-wrapper {
    position: relative;
}


.news-folder-sub-news-date-wrapper {
    position: absolute;
    text-transform: uppercase;
    background: $yellow;
    width: 55px;
    height: 55px;
    color: white;
    padding: 5px;
    z-index: 1;
    text-align: center;
    left: 20px;
    bottom: -2px;
}

//News Page
.u-paging {
    text-align: center;

    .u-paging-li {
        display: inline-block;
    }
}

.news-page-date-wrapper-wrapper {
    position: relative;
}

.news-page-date-wrapper {
    position: absolute;
    text-transform: uppercase;
    background: $yellow;
    width: 55px;
    height: 55px;
    color: white;
    padding: 5px;
    z-index: 1;
    text-align: center;
    top: 20%;
    left: 0%;
}

.news-separator {
    margin-top: 35px;
    padding-bottom: 10px;
}
.news-main-image-wrapper {
    padding-bottom: 20px;
}
.news-page-news-header-title {
    text-align: center;
    font-weight: bold;
    padding-top: 20px;
    padding-bottom: 20px;
}

.news-page-actual-news-header-title {
    text-align: center;
    font-weight: bold;
    padding-top: 20px;
    padding-bottom: 20px;
}


.news-content {
    font-size: 18px;

    @media only screen and (min-width: 40.0625em) {
        font-size: 22px;
    }
}

.news-ingress {
    font-size: 22px !important;

    @media only screen and (min-width: 40.0625em) {
        font-size: 26px !important;  
    }
}
.news-description {
    font-size: 18px;

    @media only screen and (min-width: 40.0625em) {
        font-size: 22px;
    }
}

.news-typography {
    font-size: 18px;

    @media only screen and (min-width: 40.0625em) {
        font-size: 22px;
    }
}
.news-sub-news-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
}

.read-more-news-archive {
    text-align: center;
    margin: 0 auto;
    padding-top: 30px;
}
.news-page-folder-bottom {
    @media only screen and (min-width: 40.0625em) {
        margin-top: 80px;
    }
}