﻿.termsLabel {
    text-decoration: underline;
    cursor: pointer;
}

.termsCloseIconBottom {
    position: relative;
    bottom: 15px;
    margin-right: 20px;
    text-align: right;
    cursor: pointer;
}

.termsContainer {
    position: relative;
    display: none;
}

.termsTextBox {
    position: relative;
    background-color: $light-gray;
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    height: auto !important;
}

#termsText {
    padding: 20px;
    color: black;
    line-height: 1.2;
    font-size: 0.9em;
}
