//
// @name Button
// @description Your standard form button.
//
// @state :hover - Highlights when hovering.
// @state :disabled - Dims the button when disabled.
// @state .primary - Indicates button is the primary action.
// @state .smaller - A smaller button
//
// @markup
//   <button>This is a button</button>
//

$border-radius: $border-radius !default;
$button-color: $button-color !default;
$button-font: $base-font-family !default;
$button-font-size: $base-font-size !default;
$button-weight: $base-font-weight !default;
$button-line-height: $base-line-height !default;
$button-text-align: center !default;
$button-margin-bottom: 0;
$button-display: inline-block;
$button-border-width: 2px !default;
$button-border-color: transparent !default;
$button-border-style: solid !default;

// We use these to control various hover effects.
$button-function-factor: -20% !default;

// Styles
$button-bg-color: blue !default;
$button-bg-color: $primary-color !default;
$button-bg-hover: scale-color($button-bg-color, $lightness: $button-function-factor) !default;

@mixin button-base($style: true, $display: $button-display) {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    text-decoration: none;
    font-family: $button-font;
    font-weight: $button-weight;
    text-align: $button-text-align;
    margin: 0 0 $button-margin-bottom;
    border: none;

    @if $display {
        display: $display;
    }
}



@mixin button-size($padding: 0.5rem, $full-width: false) {
    @if $padding {
        padding: $padding ($padding * 2) ($padding) ($padding * 2);
    }

    @if $full-width {
        @if $padding {
            padding-bottom: $padding + rem-calc(1);
            padding-top: $padding;
        }
        @else if $padding == false {
            padding-bottom: 0;
            padding-top: 0;
        }

        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }
}


@mixin button-style($bg: $button-bg-color, $radius: false, $disabled: false, $bg-hover: null, $border-color: $button-border-color, $border-width: $button-border-width, $border-style: $button-border-style, $border-hover: transparent) {
    background-color: $bg;
    border-radius: $radius;
    color: $button-color;
    font-size: $button-font-size;
    font-weight: $button-weight;
    line-height: $button-line-height;
    border: $border-width $border-style $border-color;
    transition: color 200ms, background-color 200ms, border 200ms;

    @if $bg-hover {
        &:hover {
            background-color: $bg-hover;
            border-color: $border-hover;
        }
    }
    @else {
        &:hover {
            background-color: darken($bg, 10%);
        }
    }
}



.button {
    @include button-base();
    @include button-style($radius: $border-radius);
    @include button-size($padding: 8px);
    padding: 9px 25px 9px 25px;

    &.primary {
        @include button-style( $bg-hover: $white);
    }

    &.secondary {
        @include button-style( $bg-hover: $white);
        background: $red;
        border: 2px solid $red;
        text-transform: none;
        color: $black;
        border-radius: 3px;
    }
}

a.button:hover {
    color: $white;
}

a.button.secondary:hover {
    color: $black;
    border: 2px solid $red;
    background: $red !important; 
    opacity: 0.85;
}

a.button.third:hover {
    color: $white;
}

.form input[type='submit'], .form input[type="reset"], .form input[type="button"], .gul-knapp {
    @include button-base();
    @include button-style($radius: $border-radius);
    @include button-size($padding: 9px);
    
    padding: 9px 25px 9px 25px; 
    background: $red;
    border: 2px solid $red;
    text-transform: none;
    color: $black;
    border-radius: 3px;
    margin-bottom:20px;
}

input[type='submit']:hover, input[type="reset"]:hover, input[type="button"]:hover, gul-knapp:hover {
    color: $black;
    border: 2px solid $red;
    background: $red !important;
}
