// CSS Reset
// See: https://github.com/jasonkarns/css-reset
@import "Vendor/Reset/reset";

// Bourbon
// -- Docs: http://bourbon.io/docs/
@import "Vendor/Bourbon/bourbon";

// Bourbon Neat:
// -- Docs: http://thoughtbot.github.io/neat-docs/latest
@import "Vendor/Neat/neat";

// Ceaser Animation Tool
// -- See: http://matthewlein.com/ceaser/
@import "Vendor/Ceaser/ceaser";

// Helpers
@import "settings";


$text-color: #272f32;
$base-font-size: 1rem;
$base-font-family: sans-serif;
$base-font-weight: normal;

$heading-font-family: serif; 
$heading-font-weight: bold;

$quiet-color: #454545;
$loud-color: #0061a2;


html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}


.page-wrapper {
    @media #{$large-only} {
        padding-top: 0;
    }

    @media #{$medium-up} {
        @include outer-container(100%);
        overflow: hidden;

    }
}
ul {
    list-style: circle;
}
.module {
    @media #{$large-only} {
        
    }

    @media #{$medium-up} {
        @include outer-container(82em);
        overflow: hidden;

    }
}
*[class^="col"] {
}

.svg-icon {
    width: 100px;
    height: 100px;
    fill: tomato;
}

.menu-button {
    display: inline-block;
}
