﻿@font-face {
    font-family: 'Futura';
    src: url('/Customers/Klei/Maxbygget/Skin/Fonts/Futura/futura-book.ttf');
    font-weight: 100;
    font-style: normal;
}


body {
    color: $base-font-color;
    font-family: $base-font-family;
    font-weight: $base-font-weight;
    line-height: scale(2, unitless);
}

a, .link {
    color: $link-color;
    text-decoration: underline;

    &:hover {
        color: $link-hover-color;
    } 
}

.h1, .h2, .h3, .h4, .h5, .h6 {
    &.tight-font {
        margin-bottom: -6px;
    }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    color: $heading-font-color;
    font-family: $heading-font-family;
}

.ingress {
    font-size: 160%;
    font-weight: 300;
    line-height: 131%;

    @media #{$small-only} {
        font-size: 140% !important;
        line-height: 100% !important;
    }
}

// Do note that these are classes, not elements
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $heading-font-family;
    font-weight: $heading-font-weight;
}

p {
    font-family: $heading-font-family;
}

h1 {
    font-weight: 100;
}

.thin {
    font-weight: 300;
}

.h1 {
    font-size: scale(5);
    font-weight: 100;

    @media #{$large-up} {
        font-size: scale(8);
        line-height: 1.2em;
    }

    @media #{$small-only} {
        line-height: 1.1;
        margin-bottom: 13px;
    }
}

.h2 {
    font-size: scale(4);

    @media #{$large-up} {
        font-size: scale(7);
    }
}

.h3 {
    font-size: scale(3);
    line-height: 1.1em;
    padding-top: 10px;
    padding-bottom: 10px;

    @media #{$large-up} {
        font-size: scale(5);
        line-height: 1.1em;
    }

    @media #{$small-only} {
        font-size: scale(2);
        line-height: 1.1em;
    }
}

.h4 {
    font-size: scale(3);

    @media #{$large-up} {
        font-size: scale(5);
    }
}

.h5 {
    font-size: scale(2);

    @media #{$large-up} {
        font-size: scale(4);
    }
}

.h6 {
    font-size: 1em;
}

.h1.not-bold,
.h2.not-bold,
.h3.not-bold,
.h4.not-bold,
.h5.not-bold,
.h6.not-bold {
    font-weight: normal;
}
//.quiet { color: $quiet-color; }
//.loud  { color: $loud-color; }
.alternate-heading {
    color: $alternate-heading-color;
}

.no-link {
    text-decoration: none;
}

.lightblue {
    color: $link-color;
}

.underline {
    text-decoration: underline !important;
}

.italic {
    font-family: $italic-font-family;
}

.regular {
    font-family: $base-font-family;
}

.bold {
    font-weight: 600;
    /*@extend .loud;*/
}

.right {
    text-align: right;
}

.larger {
    font-size: 130%;
}

.medium {
    font-size: 110%;
}

a.white.link:hover {
    opacity: 0.8;
}

.uppercase {
    text-transform: uppercase;
}

.center {
    text-align: center;
}

@media #{$small-only} {
    .s-center {
        text-align: center;
    }
}

@media #{$small-up} {
    .s-up-center {
        text-align: center;
    }
}

@media #{$medium-only} {
    .m-center {
        text-align: center;
    }
}

@media #{$medium-up} {
    .m-up-center {
        text-align: center;
    }
}

@media #{$large-up} {
    .l-center {
        text-align: center;
    }
}

@media #{$large-only} {
    .l-up-center {
        text-align: center;
    }
}

.s-up-center {
}

.m-center {
}

.m-up-center {
}

.l-center {
}

.l-up-center {
}



.unordered-list {
    list-style-type: disc;
}

.ordered-list {
    list-style: decimal;
}

.horizontal-list > * {
    display: inline-block;
    list-style: none;
}

.block-margins {
    margin: 1em 0;
}

.small-links {
    font-size: 0.85em;
}

b, strong, .strong {
   
}

.h2 {

    @media #{$small-only} {
        font-size: 2.98598rem !important;
        line-height: 1em;
        margin-bottom: 5px !important;
    }
}

.typography {
    i, em {
    
    }

    b, strong, .strong {

    }

    h1, .h1 {
  
        margin: 1em 0 0.5em;
    }

    h2, .h2 {
      
        margin: 1em 0 0.5em;

        @media #{$small-only} {
            /*font-size: 2.98598rem !important;*/
            line-height: 1em;
            margin-bottom: 5px !important;
        }
    }

    h3, .h3 {
     
        margin: 1em 0 0.5em;
    }

    h4, .h4 {
  
        margin: 1em 0 0.5em;
    }

    h5, .h5 {
 
        margin: 1em 0 0.5em;
    }

    h6, .h6 {
    
        margin: 1em 0 0.5em;
    }

    p, ul, ol, pre {
    
    }

    ul, ol {
        list-style-position: outside;
        padding-left: 1.55em;
    }

    ul {
      
    }

    ol {
   
    }

    pre, code {
    
    }
}

.quote-text {
    font-family: "Calluna";
    font-style: italic;
}
