/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import "https://cdn.jsdelivr.net/npm/animate.css@3.5.1";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

/* Don't kill focus outline for keyboard users: http://24ways.org/2009/dont-lose-your-focus */
a:hover, a:active {
  outline: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  box-sizing: border-box;
}

*, *::after, *::before {
  box-sizing: inherit;
}

@font-face {
  font-family: 'Futura';
  src: url("/Customers/Klei/Maxbygget/Skin/Fonts/Futura/futura-book.ttf");
  font-weight: 100;
  font-style: normal;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

/* Don't kill focus outline for keyboard users: http://24ways.org/2009/dont-lose-your-focus */
a:hover, a:active {
  outline: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  box-sizing: border-box;
}

*, *::after, *::before {
  box-sizing: inherit;
}

/**
 * @name Base
 * @description These are your basic text styles
 */
@font-face {
  font-family: 'Futura';
  src: url("/Customers/Klei/Maxbygget/Skin/Fonts/Futura/futura-book.ttf");
  font-weight: 100;
  font-style: normal;
}

body {
  color: #ffffff;
  font-family: "Futura", Calibri, Candara, Segoe UI, Optima, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.44;
}

a, .link {
  color: #EA5D0B;
  text-decoration: underline;
}

a:hover, .link:hover {
  color: #859DAF;
}

.h1.tight-font, .h2.tight-font, .h3.tight-font, .h4.tight-font, .h5.tight-font, .h6.tight-font {
  margin-bottom: -6px;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: #ffffff;
  font-family: "Futura", Calibri, Candara, Segoe UI, Optima, Arial, sans-serif !important;
}

.ingress {
  font-size: 160%;
  font-weight: 300;
  line-height: 131%;
}

@media only screen and (max-width: 40em) {
  .ingress {
    font-size: 140% !important;
    line-height: 100% !important;
  }
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Futura", Calibri, Candara, Segoe UI, Optima, Arial, sans-serif !important;
  font-weight: 700;
}

p {
  font-family: "Futura", Calibri, Candara, Segoe UI, Optima, Arial, sans-serif !important;
}

h1 {
  font-weight: 100;
}

.thin {
  font-weight: 300;
}

.h1 {
  font-size: 2.48832rem;
  font-weight: 100;
}

@media only screen and (min-width: 64.0625em) {
  .h1 {
    font-size: 4.29982rem;
    line-height: 1.2em;
  }
}

@media only screen and (max-width: 40em) {
  .h1 {
    line-height: 1.1;
    margin-bottom: 13px;
  }
}

.h2 {
  font-size: 2.0736rem;
}

@media only screen and (min-width: 64.0625em) {
  .h2 {
    font-size: 3.58318rem;
  }
}

.h3 {
  font-size: 1.728rem;
  line-height: 1.1em;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media only screen and (min-width: 64.0625em) {
  .h3 {
    font-size: 2.48832rem;
    line-height: 1.1em;
  }
}

@media only screen and (max-width: 40em) {
  .h3 {
    font-size: 1.44rem;
    line-height: 1.1em;
  }
}

.h4 {
  font-size: 1.728rem;
}

@media only screen and (min-width: 64.0625em) {
  .h4 {
    font-size: 2.48832rem;
  }
}

.h5 {
  font-size: 1.44rem;
}

@media only screen and (min-width: 64.0625em) {
  .h5 {
    font-size: 2.0736rem;
  }
}

.h6 {
  font-size: 1em;
}

.h1.not-bold,
.h2.not-bold,
.h3.not-bold,
.h4.not-bold,
.h5.not-bold,
.h6.not-bold {
  font-weight: normal;
}

.alternate-heading {
  color: #ffffff;
}

.no-link {
  text-decoration: none;
}

.lightblue {
  color: #EA5D0B;
}

.underline {
  text-decoration: underline !important;
}

.italic {
  font-family: "Futura", Calibri, Candara, Segoe UI, Optima, Arial, sans-serif;
}

.regular {
  font-family: "Futura", Calibri, Candara, Segoe UI, Optima, Arial, sans-serif;
}

.bold {
  font-weight: 600;
  /*@extend .loud;*/
}

.right {
  text-align: right;
}

.larger {
  font-size: 130%;
}

.medium {
  font-size: 110%;
}

a.white.link:hover {
  opacity: 0.8;
}

.uppercase {
  text-transform: uppercase;
}

.center {
  text-align: center;
}

@media only screen and (max-width: 40em) {
  .s-center {
    text-align: center;
  }
}

@media only screen {
  .s-up-center {
    text-align: center;
  }
}

@media only screen and (min-width: 40.0625em) and (max-width: 64em) {
  .m-center {
    text-align: center;
  }
}

@media only screen and (min-width: 40.0625em) {
  .m-up-center {
    text-align: center;
  }
}

@media only screen and (min-width: 64.0625em) {
  .l-center {
    text-align: center;
  }
}

@media only screen and (min-width: 64.0625em) and (max-width: 90em) {
  .l-up-center {
    text-align: center;
  }
}

.unordered-list {
  list-style-type: disc;
}

.ordered-list {
  list-style: decimal;
}

.horizontal-list > * {
  display: inline-block;
  list-style: none;
}

.block-margins {
  margin: 1em 0;
}

.small-links {
  font-size: 0.85em;
}

@media only screen and (max-width: 40em) {
  .h2 {
    font-size: 2.98598rem !important;
    line-height: 1em;
    margin-bottom: 5px !important;
  }
}

.typography h1, .typography .h1 {
  margin: 1em 0 0.5em;
}

.typography h2, .typography .h2 {
  margin: 1em 0 0.5em;
}

@media only screen and (max-width: 40em) {
  .typography h2, .typography .h2 {
    /*font-size: 2.98598rem !important;*/
    line-height: 1em;
    margin-bottom: 5px !important;
  }
}

.typography h3, .typography .h3 {
  margin: 1em 0 0.5em;
}

.typography h4, .typography .h4 {
  margin: 1em 0 0.5em;
}

.typography h5, .typography .h5 {
  margin: 1em 0 0.5em;
}

.typography h6, .typography .h6 {
  margin: 1em 0 0.5em;
}

.typography ul, .typography ol {
  list-style-position: outside;
  padding-left: 1.55em;
}

.quote-text {
  font-family: "Calluna";
  font-style: italic;
}

[class*="column"] {
  display: block;
  float: left;
}

@media only screen {
  .s-up-1 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 6.17215%;
  }
  .s-up-1:last-child {
    margin-right: 0;
  }
  .s-up-push-1 {
    margin-left: 8.5298%;
  }
  .s-up-2 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 14.70196%;
  }
  .s-up-2:last-child {
    margin-right: 0;
  }
  .s-up-push-2 {
    margin-left: 17.05961%;
  }
  .s-up-3 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
  }
  .s-up-3:last-child {
    margin-right: 0;
  }
  .s-up-push-3 {
    margin-left: 25.58941%;
  }
  .s-up-4 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
  }
  .s-up-4:last-child {
    margin-right: 0;
  }
  .s-up-push-4 {
    margin-left: 34.11922%;
  }
  .s-up-5 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%;
  }
  .s-up-5:last-child {
    margin-right: 0;
  }
  .s-up-push-5 {
    margin-left: 42.64902%;
  }
  .s-up-6 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
  }
  .s-up-6:last-child {
    margin-right: 0;
  }
  .s-up-push-6 {
    margin-left: 51.17883%;
  }
  .s-up-7 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 57.35098%;
  }
  .s-up-7:last-child {
    margin-right: 0;
  }
  .s-up-push-7 {
    margin-left: 59.70863%;
  }
  .s-up-8 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%;
  }
  .s-up-8:last-child {
    margin-right: 0;
  }
  .s-up-push-8 {
    margin-left: 68.23843%;
  }
  .s-up-9 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%;
  }
  .s-up-9:last-child {
    margin-right: 0;
  }
  .s-up-push-9 {
    margin-left: 76.76824%;
  }
  .s-up-10 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%;
  }
  .s-up-10:last-child {
    margin-right: 0;
  }
  .s-up-push-10 {
    margin-left: 85.29804%;
  }
  .s-up-11 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 91.4702%;
  }
  .s-up-11:last-child {
    margin-right: 0;
  }
  .s-up-push-11 {
    margin-left: 93.82785%;
  }
  .s-up-12 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
  }
  .s-up-12:last-child {
    margin-right: 0;
  }
  .s-up-push-12 {
    margin-left: 102.35765%;
  }
}

@media only screen and (max-width: 40em) {
  .s-1 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 6.17215%;
  }
  .s-1:last-child {
    margin-right: 0;
  }
  .s-push-1 {
    margin-left: 8.5298%;
  }
  .s-2 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 14.70196%;
  }
  .s-2:last-child {
    margin-right: 0;
  }
  .s-push-2 {
    margin-left: 17.05961%;
  }
  .s-3 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
  }
  .s-3:last-child {
    margin-right: 0;
  }
  .s-push-3 {
    margin-left: 25.58941%;
  }
  .s-4 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
  }
  .s-4:last-child {
    margin-right: 0;
  }
  .s-push-4 {
    margin-left: 34.11922%;
  }
  .s-5 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%;
  }
  .s-5:last-child {
    margin-right: 0;
  }
  .s-push-5 {
    margin-left: 42.64902%;
  }
  .s-6 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
  }
  .s-6:last-child {
    margin-right: 0;
  }
  .s-push-6 {
    margin-left: 51.17883%;
  }
  .s-7 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 57.35098%;
  }
  .s-7:last-child {
    margin-right: 0;
  }
  .s-push-7 {
    margin-left: 59.70863%;
  }
  .s-8 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%;
  }
  .s-8:last-child {
    margin-right: 0;
  }
  .s-push-8 {
    margin-left: 68.23843%;
  }
  .s-9 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%;
  }
  .s-9:last-child {
    margin-right: 0;
  }
  .s-push-9 {
    margin-left: 76.76824%;
  }
  .s-10 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%;
  }
  .s-10:last-child {
    margin-right: 0;
  }
  .s-push-10 {
    margin-left: 85.29804%;
  }
  .s-11 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 91.4702%;
  }
  .s-11:last-child {
    margin-right: 0;
  }
  .s-push-11 {
    margin-left: 93.82785%;
  }
  .s-12 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
  }
  .s-12:last-child {
    margin-right: 0;
  }
  .s-push-12 {
    margin-left: 102.35765%;
  }
}

@media only screen and (min-width: 40.0625em) {
  .m-up-1 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 6.17215%;
  }
  .m-up-1:last-child {
    margin-right: 0;
  }
  .m-up-push-1 {
    margin-left: 8.5298%;
  }
  .m-up-2 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 14.70196%;
  }
  .m-up-2:last-child {
    margin-right: 0;
  }
  .m-up-push-2 {
    margin-left: 17.05961%;
  }
  .m-up-3 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
  }
  .m-up-3:last-child {
    margin-right: 0;
  }
  .m-up-push-3 {
    margin-left: 25.58941%;
  }
  .m-up-4 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
  }
  .m-up-4:last-child {
    margin-right: 0;
  }
  .m-up-push-4 {
    margin-left: 34.11922%;
  }
  .m-up-5 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%;
  }
  .m-up-5:last-child {
    margin-right: 0;
  }
  .m-up-push-5 {
    margin-left: 42.64902%;
  }
  .m-up-6 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
  }
  .m-up-6:last-child {
    margin-right: 0;
  }
  .m-up-push-6 {
    margin-left: 51.17883%;
  }
  .m-up-7 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 57.35098%;
  }
  .m-up-7:last-child {
    margin-right: 0;
  }
  .m-up-push-7 {
    margin-left: 59.70863%;
  }
  .m-up-8 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%;
  }
  .m-up-8:last-child {
    margin-right: 0;
  }
  .m-up-push-8 {
    margin-left: 68.23843%;
  }
  .m-up-9 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%;
  }
  .m-up-9:last-child {
    margin-right: 0;
  }
  .m-up-push-9 {
    margin-left: 76.76824%;
  }
  .m-up-10 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%;
  }
  .m-up-10:last-child {
    margin-right: 0;
  }
  .m-up-push-10 {
    margin-left: 85.29804%;
  }
  .m-up-11 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 91.4702%;
  }
  .m-up-11:last-child {
    margin-right: 0;
  }
  .m-up-push-11 {
    margin-left: 93.82785%;
  }
  .m-up-12 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
  }
  .m-up-12:last-child {
    margin-right: 0;
  }
  .m-up-push-12 {
    margin-left: 102.35765%;
  }
}

@media only screen and (min-width: 40.0625em) and (max-width: 64em) {
  .m-1 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 6.17215%;
  }
  .m-1:last-child {
    margin-right: 0;
  }
  .m-push-1 {
    margin-left: 8.5298%;
  }
  .m-2 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 14.70196%;
  }
  .m-2:last-child {
    margin-right: 0;
  }
  .m-push-2 {
    margin-left: 17.05961%;
  }
  .m-3 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
  }
  .m-3:last-child {
    margin-right: 0;
  }
  .m-push-3 {
    margin-left: 25.58941%;
  }
  .m-4 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
  }
  .m-4:last-child {
    margin-right: 0;
  }
  .m-push-4 {
    margin-left: 34.11922%;
  }
  .m-5 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%;
  }
  .m-5:last-child {
    margin-right: 0;
  }
  .m-push-5 {
    margin-left: 42.64902%;
  }
  .m-6 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
  }
  .m-6:last-child {
    margin-right: 0;
  }
  .m-push-6 {
    margin-left: 51.17883%;
  }
  .m-7 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 57.35098%;
  }
  .m-7:last-child {
    margin-right: 0;
  }
  .m-push-7 {
    margin-left: 59.70863%;
  }
  .m-8 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%;
  }
  .m-8:last-child {
    margin-right: 0;
  }
  .m-push-8 {
    margin-left: 68.23843%;
  }
  .m-9 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%;
  }
  .m-9:last-child {
    margin-right: 0;
  }
  .m-push-9 {
    margin-left: 76.76824%;
  }
  .m-10 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%;
  }
  .m-10:last-child {
    margin-right: 0;
  }
  .m-push-10 {
    margin-left: 85.29804%;
  }
  .m-11 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 91.4702%;
  }
  .m-11:last-child {
    margin-right: 0;
  }
  .m-push-11 {
    margin-left: 93.82785%;
  }
  .m-12 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
  }
  .m-12:last-child {
    margin-right: 0;
  }
  .m-push-12 {
    margin-left: 102.35765%;
  }
}

@media only screen and (min-width: 64.0625em) {
  .l-up-1 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 6.17215%;
  }
  .l-up-1:last-child {
    margin-right: 0;
  }
  .l-up-push-1 {
    margin-left: 8.5298%;
  }
  .l-up-2 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 14.70196%;
  }
  .l-up-2:last-child {
    margin-right: 0;
  }
  .l-up-push-2 {
    margin-left: 17.05961%;
  }
  .l-up-3 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
  }
  .l-up-3:last-child {
    margin-right: 0;
  }
  .l-up-push-3 {
    margin-left: 25.58941%;
  }
  .l-up-4 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
  }
  .l-up-4:last-child {
    margin-right: 0;
  }
  .l-up-push-4 {
    margin-left: 34.11922%;
  }
  .l-up-5 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%;
  }
  .l-up-5:last-child {
    margin-right: 0;
  }
  .l-up-push-5 {
    margin-left: 42.64902%;
  }
  .l-up-6 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
  }
  .l-up-6:last-child {
    margin-right: 0;
  }
  .l-up-push-6 {
    margin-left: 51.17883%;
  }
  .l-up-7 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 57.35098%;
  }
  .l-up-7:last-child {
    margin-right: 0;
  }
  .l-up-push-7 {
    margin-left: 59.70863%;
  }
  .l-up-8 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%;
  }
  .l-up-8:last-child {
    margin-right: 0;
  }
  .l-up-push-8 {
    margin-left: 68.23843%;
  }
  .l-up-9 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%;
  }
  .l-up-9:last-child {
    margin-right: 0;
  }
  .l-up-push-9 {
    margin-left: 76.76824%;
  }
  .l-up-10 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%;
  }
  .l-up-10:last-child {
    margin-right: 0;
  }
  .l-up-push-10 {
    margin-left: 85.29804%;
  }
  .l-up-11 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 91.4702%;
  }
  .l-up-11:last-child {
    margin-right: 0;
  }
  .l-up-push-11 {
    margin-left: 93.82785%;
  }
  .l-up-12 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
  }
  .l-up-12:last-child {
    margin-right: 0;
  }
  .l-up-push-12 {
    margin-left: 102.35765%;
  }
}

@media only screen and (min-width: 64.0625em) and (max-width: 90em) {
  .l-1 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 6.17215%;
  }
  .l-1:last-child {
    margin-right: 0;
  }
  .l-push-1 {
    margin-left: 8.5298%;
  }
  .l-2 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 14.70196%;
  }
  .l-2:last-child {
    margin-right: 0;
  }
  .l-push-2 {
    margin-left: 17.05961%;
  }
  .l-3 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
  }
  .l-3:last-child {
    margin-right: 0;
  }
  .l-push-3 {
    margin-left: 25.58941%;
  }
  .l-4 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
  }
  .l-4:last-child {
    margin-right: 0;
  }
  .l-push-4 {
    margin-left: 34.11922%;
  }
  .l-5 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 40.29137%;
  }
  .l-5:last-child {
    margin-right: 0;
  }
  .l-push-5 {
    margin-left: 42.64902%;
  }
  .l-6 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
  }
  .l-6:last-child {
    margin-right: 0;
  }
  .l-push-6 {
    margin-left: 51.17883%;
  }
  .l-7 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 57.35098%;
  }
  .l-7:last-child {
    margin-right: 0;
  }
  .l-push-7 {
    margin-left: 59.70863%;
  }
  .l-8 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%;
  }
  .l-8:last-child {
    margin-right: 0;
  }
  .l-push-8 {
    margin-left: 68.23843%;
  }
  .l-9 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 74.41059%;
  }
  .l-9:last-child {
    margin-right: 0;
  }
  .l-push-9 {
    margin-left: 76.76824%;
  }
  .l-10 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 82.94039%;
  }
  .l-10:last-child {
    margin-right: 0;
  }
  .l-push-10 {
    margin-left: 85.29804%;
  }
  .l-11 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 91.4702%;
  }
  .l-11:last-child {
    margin-right: 0;
  }
  .l-push-11 {
    margin-left: 93.82785%;
  }
  .l-12 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
  }
  .l-12:last-child {
    margin-right: 0;
  }
  .l-push-12 {
    margin-left: 102.35765%;
  }
}

.row::after {
  clear: both;
  content: "";
  display: table;
}

.termsLabel {
  text-decoration: underline;
  cursor: pointer;
}

.termsCloseIconBottom {
  position: relative;
  bottom: 15px;
  margin-right: 20px;
  text-align: right;
  cursor: pointer;
}

.termsContainer {
  position: relative;
  display: none;
}

.termsTextBox {
  position: relative;
  background-color: #cbcbcb;
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  height: auto !important;
}

#termsText {
  padding: 20px;
  color: black;
  line-height: 1.2;
  font-size: 0.9em;
}

/**
 * @name Layout
 * @description Grids and things
 */
.button {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  text-decoration: none;
  font-family: "Futura", Calibri, Candara, Segoe UI, Optima, Arial, sans-serif;
  font-weight: regular;
  text-align: center;
  margin: 0 0 0;
  border: none;
  display: inline-block;
  background-color: #859DAF;
  border-radius: 0px;
  color: white;
  font-size: 1em;
  font-weight: regular;
  line-height: 1.2em;
  border: 2px solid transparent;
  transition: color 200ms, background-color 200ms, border 200ms;
  padding: 8px 16px 8px 16px;
  padding: 9px 25px 9px 25px;
}

.button:hover {
  background-color: #66849b;
}

.button.primary {
  background-color: #859DAF;
  border-radius: false;
  color: white;
  font-size: 1em;
  font-weight: regular;
  line-height: 1.2em;
  border: 2px solid transparent;
  transition: color 200ms, background-color 200ms, border 200ms;
}

.button.primary:hover {
  background-color: #ffffff;
  border-color: transparent;
}

.button.secondary {
  background-color: #859DAF;
  border-radius: false;
  color: white;
  font-size: 1em;
  font-weight: regular;
  line-height: 1.2em;
  border: 2px solid transparent;
  transition: color 200ms, background-color 200ms, border 200ms;
  background: #EA5D0B;
  border: 2px solid #EA5D0B;
  text-transform: none;
  color: #000000;
  border-radius: 3px;
}

.button.secondary:hover {
  background-color: #ffffff;
  border-color: transparent;
}

a.button:hover {
  color: #ffffff;
}

a.button.secondary:hover {
  color: #000000;
  border: 2px solid #EA5D0B;
  background: #EA5D0B !important;
  opacity: 0.85;
}

a.button.third:hover {
  color: #ffffff;
}

.form input[type='submit'], .form input[type="reset"], .form input[type="button"], .gul-knapp {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  text-decoration: none;
  font-family: "Futura", Calibri, Candara, Segoe UI, Optima, Arial, sans-serif;
  font-weight: regular;
  text-align: center;
  margin: 0 0 0;
  border: none;
  display: inline-block;
  background-color: #859DAF;
  border-radius: 0px;
  color: white;
  font-size: 1em;
  font-weight: regular;
  line-height: 1.2em;
  border: 2px solid transparent;
  transition: color 200ms, background-color 200ms, border 200ms;
  padding: 9px 18px 9px 18px;
  padding: 9px 25px 9px 25px;
  background: #EA5D0B;
  border: 2px solid #EA5D0B;
  text-transform: none;
  color: #000000;
  border-radius: 3px;
  margin-bottom: 20px;
}

.form input[type='submit']:hover, .form input[type="reset"]:hover, .form input[type="button"]:hover, .gul-knapp:hover {
  background-color: #66849b;
}

input[type='submit']:hover, input[type="reset"]:hover, input[type="button"]:hover, gul-knapp:hover {
  color: #000000;
  border: 2px solid #EA5D0B;
  background: #EA5D0B !important;
}

body {
  background: #212121;
}

@media only screen and (min-width: 1345px) {
  body {
    background: #212121;
  }
}

.main-content-max-width, .photoMosaic {
  max-width: 110em;
  margin: 0 auto;
}

.block {
  display: block;
}

.center-block.column {
  margin: 0 auto;
  float: none;
}

.page-wrapper-inner {
  background: #212121;
  overflow: hidden;
}

.inner-wrapper {
  margin: 0 auto;
  float: none;
  width: 74.41059%;
  overflow: hidden;
}

@media only screen and (max-width: 40em) {
  .inner-wrapper {
    width: 100%;
  }
}

.no-margin {
  margin: 0 !important;
}

.img-fill-box img {
  width: 100%;
  display: block;
}

.padding {
  padding: 60px 0;
  border-top: 1px solid #6d6e71 !important;
}

@media only screen and (max-width: 40em) {
  .padding {
    padding: 40px 0;
  }
}

.padding-top {
  padding-top: 6em;
}

@media only screen and (max-width: 40em) {
  .padding-top {
    padding-top: 40px;
  }
}

.extra-padding-top {
  padding-top: 9em;
}

@media only screen and (max-width: 40em) {
  .extra-padding-top {
    padding-top: 40px;
  }
}

.medium-padding {
  padding: 40px;
}

@media only screen and (max-width: 40em) {
  .medium-padding {
    padding: 20px;
  }
}

.large-margin-top {
  margin-top: 60px !important;
}

.medium-margin {
  margin: 40px;
}

@media only screen and (max-width: 40em) {
  .medium-margin {
    margin: 20px;
  }
}

.large-padding {
  padding: 60px;
}

@media only screen and (max-width: 40em) {
  .large-padding {
    padding: 40px 20px;
  }
}

.small-padding {
  padding: 20px;
}

.tiny-padding {
  padding: 10px;
}

.small-padding-bottom {
  padding-bottom: 20px;
}

.small-margin-bottom {
  margin-bottom: 20px;
}

.small-margin-top {
  margin-top: 20px;
}

.medium-padding-bottom {
  padding-bottom: 40px;
}

.large-padding-bottom {
  padding-bottom: 60px;
}

.large-padding-top {
  padding-top: 60px;
}

.padding-bottom {
  padding-bottom: 6em;
}

@media only screen and (max-width: 40em) {
  .padding-bottom {
    padding-bottom: 1em;
  }
}

.full-width {
  min-width: 100%;
}

.illustration-picture {
  background: #212121;
  border-bottom: 1px solid #212121;
}

section {
  position: relative;
}

.min-height {
  min-height: 800px;
}

@media only screen and (min-width: 40.0625em) and (max-width: 64em) {
  .min-height {
    min-height: 650px;
  }
}

@media only screen and (max-width: 40em) {
  .min-height {
    min-height: 500px;
  }
}

.quote-img {
  min-height: 300px;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

.bottom-left-to-right-diagonal:after {
  top: auto;
  bottom: 56px;
}

section::before, section::after {
  position: absolute;
  content: '';
  pointer-events: none;
}

.white-bg {
  background: #212121;
  border: 1px solid #212121;
}

.green-bg {
  background: #F2B231;
  color: #000000;
}

.prospect {
  padding: 2em 9em;
  margin-bottom: 20em !important;
}

.prospect-banner {
  opacity: 0.95;
}

#max-bygget-prospect-logo {
  width: 200px;
}

.blue-bg {
  background: #304344;
  color: #ffffff;
  opacity: 0.84;
}

.blue-bg h2, .blue-bg h3, .blue-bg h4, .blue-bg h5, .blue-bg h6,
.blue-bg .h2, .blue-bg .h3, .blue-bg .h4, .blue-bg .h5, .blue-bg .h6 {
  color: #ffffff;
}

.blue-bg h1, .blue-bg .h1 {
  color: #EA5D0B;
}

.transparent-white-bg {
  background: #212121;
}

.dark-gray-bg {
  background: #6d6e71;
  color: #ffffff;
}

.dark-gray-bg h1, .dark-gray-bg h2, .dark-gray-bg h3, .dark-gray-bg h4, .dark-gray-bg h5, .dark-gray-bg h6 {
  color: #ffffff;
}

.white {
  color: #212121 !important;
}

.clear {
  clear: both;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.overflow {
  overflow: auto;
}

.align-center {
  text-align: center;
}

.max-blue {
  fill: #859daf;
}

.max-red {
  fill: #ce4512;
}

.max-yellow {
  fill: #f4b211;
}

.aspect-16-9 {
  padding-top: 0;
  height: 350px;
  overflow: hidden;
}

@media only screen and (min-width: 40.0625em) {
  .aspect-16-9 {
    padding-top: 56.25%;
  }
}

@media only screen and (min-width: 90.0625em) {
  .aspect-16-9 {
    padding-top: 0;
    height: 800px;
  }
}

.top-image-wrapper,
.top-video-nor-wrapper,
.top-video-eng-wrapper {
  position: absolute;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 0;
  min-width: 640px;
  transform: translateX(-50%);
  overflow: hidden;
  transition: opacity 1.5s ease-out, visibility 1.5s ease, z-index 0s linear 1.5s;
  opacity: 1;
  visibility: visible;
}

@media only screen and (min-width: 40.0625em) {
  .top-image-wrapper,
  .top-video-nor-wrapper,
  .top-video-eng-wrapper {
    left: 0;
    transform: none;
  }
}

.is-playing-nor-video .top-image-wrapper,
.is-playing-eng-video .top-image-wrapper, .is-playing-nor-video
.top-video-nor-wrapper,
.is-playing-eng-video
.top-video-nor-wrapper, .is-playing-nor-video
.top-video-eng-wrapper,
.is-playing-eng-video
.top-video-eng-wrapper {
  transition: opacity 1.5s ease-out, visibility 1.5s ease, z-index 0s linear;
}

.top-image-wrapper > video,
.top-image-wrapper > img,
.top-video-nor-wrapper > video,
.top-video-nor-wrapper > img,
.top-video-eng-wrapper > video,
.top-video-eng-wrapper > img {
  position: absolute;
  display: block;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media only screen and (min-width: 90.0625em) {
  .top-image-wrapper > video,
  .top-image-wrapper > img,
  .top-video-nor-wrapper > video,
  .top-video-nor-wrapper > img,
  .top-video-eng-wrapper > video,
  .top-video-eng-wrapper > img {
    top: 50%;
    transform: translateY(-50%);
  }
}

.is-playing-nor-video .top-image-wrapper,
.is-playing-eng-video .top-image-wrapper {
  opacity: 0;
  visibility: hidden;
}

.top-image-overlays {
  position: absolute;
  z-index: 6;
  top: 24px;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}

@media only screen and (min-width: 40.0625em) {
  .top-image-overlays {
    top: 2vw;
  }
}

.top-image-overlays #max-animated-logo {
  width: 35%;
  float: left;
  padding-left: 30px;
  display: block;
  max-width: 800px;
  transition: opacity .5s ease, transform .5s ease, visibility .5s ease;
}

@media only screen and (min-width: 40.0625em) {
  .top-image-overlays #max-animated-logo {
    width: 25%;
  }
}

@media only screen and (min-width: 64.0625em) {
  .top-image-overlays #max-animated-logo {
    width: 15%;
  }
}

.is-playing-nor-video .top-image-overlays #max-animated-logo,
.is-playing-eng-video .top-image-overlays #max-animated-logo {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
}

.top-image-overlays .main-controls {
  display: block;
  position: relative;
  margin-top: 250px;
  text-align: center;
  opacity: 1;
  visibility: visible;
  transition: opacity .7s ease, transform .7s ease, visibility .7s ease;
}

@media only screen and (min-width: 40.0625em) {
  .top-image-overlays .main-controls {
    margin-top: 25vw;
  }
}

@media only screen and (min-width: 90.0625em) {
  .top-image-overlays .main-controls {
    margin-top: 15vw;
  }
}

.is-playing-nor-video .top-image-overlays .main-controls,
.is-playing-eng-video .top-image-overlays .main-controls {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
}

.top-image-overlays .main-controls > div {
  display: inline-block;
  background: rgba(0, 0, 0, 0.7);
  padding: 7px 12px 7px 58px;
  height: 50px;
  text-align: left;
  cursor: pointer;
  border-radius: 3px;
  position: relative;
}

.top-image-overlays .main-controls > div:first-child {
  margin-right: 10px;
}

.top-image-overlays .main-controls > div svg {
  display: block;
  position: absolute;
  width: 36px;
  height: 36px;
  top: 7px;
  left: 12px;
}

.top-image-overlays .main-controls > div i {
  position: relative;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  font-style: normal;
  color: #ffffff;
}

.top-image-overlays .main-controls > div .svg-fill {
  fill: #EA5D0B;
  transition: all .5s ease;
}

.top-image-overlays .main-controls > div:hover .svg-fill {
  fill: #ffffff;
}

.top-image-overlays .controls {
  position: absolute;
  right: 23px;
  bottom: 23px;
  width: 49px;
  height: 49px;
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease;
}

.top-image-overlays #pause-button {
  display: block;
  cursor: pointer;
}

.top-image-overlays #pause-button svg {
  width: 49px;
  height: 49px;
}

.top-image-overlays #pause-button .svg-fill {
  fill: #ffffff;
  transition: all .5s ease;
}

.top-image-overlays #pause-button:hover .svg-fill {
  fill: #EA5D0B;
}

.is-playing-nor-video .top-image-overlays .controls,
.is-playing-eng-video .top-image-overlays .controls {
  opacity: 1;
  visibility: visible;
}

.is-playing-nor-video .top-image-overlays .main-controls,
.is-playing-eng-video .top-image-overlays .main-controls {
  opacity: 0;
  visibility: hidden;
}

.typography img,
.typography video {
  width: 100%;
}

.typography p {
  font-size: 18px;
  padding-bottom: 15px;
}

@media only screen and (min-width: 40.0625em) {
  .typography p {
    font-size: 22px;
  }
}

.typography p img {
  width: 100% !important;
  height: auto !important;
  padding-top: 5px;
  padding-bottom: 5px;
  max-height: 750px;
  object-fit: cover;
}

@media only screen and (min-width: 40.0625em) {
  .typography p img {
    max-height: 650px;
  }
}

.hidden-content {
  position: relative;
  overflow: hidden;
  max-height: 100px;
  transition: max-height .5s ease-out;
}

.hidden-content:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  opacity: 1;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #222222 0.8);
  transition: all .5s ease-out;
  pointer-events: none;
}

.hidden-content.open {
  max-height: 3200px;
  transition: max-height .5s ease-in;
}

.hidden-content.open:before {
  opacity: 0;
  z-index: -1;
}

.top-image-wrapper {
  z-index: 5;
}

.top-video-nor-wrapper,
.top-video-eng-wrapper {
  z-index: 3;
}

.is-playing-nor-video .top-video-nor-wrapper {
  z-index: 4;
}

.is-playing-eng-video .top-video-eng-wrapper {
  z-index: 4;
}

.text-overlay-top {
  position: absolute;
  top: 35%;
  left: 5%;
}

.text-overlay-toph1, .text-overlay-top.h1 {
  color: #fff;
  letter-spacing: 2px;
  background: rgba(56, 135, 121, 0.79);
  box-shadow: 0px 0px 108px 47px rgba(56, 135, 121, 0.85);
}

.video-control {
  right: 20px;
  bottom: 20px;
  z-index: 999;
}

.video-wrapper video, .video-wrapper img {
  width: 100%;
  display: block;
}

.video-wrapper-inner {
  margin: 0 auto;
  margin-bottom: 60px;
  position: relative;
}

@media only screen and (min-width: 64.0625em) {
  .video-wrapper-inner {
    max-width: 65.88078%;
  }
}

.video-wrapper-inner img {
  display: block;
}

.logo-wrapper-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.logo-wrapper-inner .video-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -80px;
  margin-top: -40PX;
}

@media only screen and (max-width: 40em) {
  .logo-wrapper-inner .video-logo {
    margin-left: -60px;
    margin-top: -30px;
  }
}

.logo-wrapper-inner .video-logo img {
  width: 100%;
}

.logo-wrapper-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.logo-wrapper-inner .picture-top-logo {
  position: absolute;
  top: 30%;
  left: 50%;
  margin-left: -400px;
  margin-top: -100px;
}

@media only screen and (max-width: 40em) {
  .logo-wrapper-inner .picture-top-logo {
    margin-left: 0;
    margin-top: 0;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (min-width: 40.0625em) and (max-width: 64em) {
  .logo-wrapper-inner .picture-top-logo {
    margin-left: -300px;
    margin-top: -50px;
  }
}

.logo-wrapper-inner .picture-top-logo img {
  width: 100%;
}

@media only screen and (min-width: 64.0625em) {
  .logo-wrapper-inner .picture-top-logo img {
    min-width: 800px;
    max-width: 100%;
  }
}

@media only screen and (min-width: 40.0625em) and (max-width: 64em) {
  .logo-wrapper-inner .picture-top-logo img {
    min-width: 600px;
    max-width: 100%;
  }
}

@media only screen and (max-width: 40em) {
  .logo-wrapper-inner .picture-top-logo img {
    max-width: none;
    width: 88vw;
  }
}

.typography .editorcontent h2 {
  font-size: 2.48832rem;
  font-weight: 100;
  text-align: center;
}

@media only screen and (min-width: 64.0625em) {
  .typography .editorcontent h2 {
    font-size: 4.29982rem !important;
    line-height: 1.2em !important;
  }
}

.partner-logo {
  display: inline-block;
  padding-right: 30px;
}

.poster-img {
  display: block;
}

.mobile-logo {
  max-width: 120px;
}

video {
  max-width: 100%;
}

.fill-image {
  margin-top: 0;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

#kunstner-bilde {
  width: 100%;
}

.logo-img-wrapper {
  display: inline-block;
}

.product-page .product__img {
  width: 100%;
  display: block;
}

.gallery-outer {
  position: relative;
  overflow: hidden;
  width: 100%;
  float: left;
  display: block;
  text-decoration: none;
}

@media only screen and (max-width: 40em) {
  .gallery-outer {
    margin: 0 auto;
    float: none;
    margin-bottom: 10px;
    width: 100%;
    padding: 0px;
    border: none;
  }
}

@media only screen and (min-width: 40.0625em) and (max-width: 64em) {
  .gallery-outer {
    margin-bottom: 2.35765%;
  }
  .gallery-outer:nth-child(2n) {
    margin-right: 0;
  }
}

@media only screen and (min-width: 40.0625em) {
  .gallery-outer .img-wrapper {
    padding: 20px 40px;
    border: 1px solid #16232c;
  }
}

.floorplan-page-wrapper {
  background: #212121;
  border: 1px solid #212121;
}

.floor-image-wrapper {
  margin-top: px;
}

.floorplan-image {
  width: 100%;
}

.floorplan-content {
  max-width: 800px;
  margin: 0 auto;
}

@media only screen and (min-width: 40.0625em) {
  .gallery-inner {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 40.0625em) {
  .gallery-inner {
    transition: ease all 500ms;
    overflow: hidden;
    transform: translateY(200%);
  }
}

@media only screen and (max-width: 40em) {
  .gallery-inner .hidden {
    display: none;
  }
}

@media only screen and (min-width: 40.0625em) {
  .gallery-outer:hover .gallery-inner {
    transform: translateY(0);
  }
}

.tripadvisor {
  top: 20%;
  right: 0;
}

@media only screen and (max-width: 40em) {
  .tripadvisor {
    position: static;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 40em) {
  .tripadvisor #CDSWIDCOE {
    margin: 0 auto;
  }
}

#map {
  position: relative;
  padding-bottom: 35%;
  height: 550px;
  overflow: hidden;
}

@media only screen and (max-width: 40em) {
  #map {
    height: 330px;
  }
}

#map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.footer-icons .footer-item {
  float: left;
  margin: 0;
  padding: 0;
  border-left: 2px solid #ffffff;
  border-top: 2px solid #ffffff;
  padding: 12px 0;
  width: 33.333%;
  min-height: 75px;
}

@media only screen and (min-width: 40.0625em) {
  .footer-icons .footer-item {
    width: 12.5%;
  }
  .footer-icons .footer-item:first-child {
    border-left: none;
  }
}

@media only screen and (max-width: 40em) {
  .footer-icons .footer-item:nth-child(3n) {
    border-right: 2px solid #ffffff;
  }
  .footer-icons .footer-item:nth-child(6) {
    border-bottom: 2px solid #ffffff;
  }
  .footer-icons .footer-item:last-child {
    border-right: 2px solid #ffffff;
  }
}

.footer-icons a.footer-item:hover img {
  opacity: 0.5;
}

.footer-icons img {
  display: block;
  width: 90%;
  margin: 0 auto;
  max-height: 40px;
}

@media only screen and (max-width: 40em) {
  .events {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 40em) {
  .event-item {
    padding-bottom: 40px;
  }
}

img {
  max-width: 100%;
}

video {
  max-width: 100%;
}

#VideoPlayControl {
  cursor: pointer;
}

#VideoPlayControl svg {
  width: 35px;
  opacity: 0.7;
}

#VideoPauseControl {
  cursor: pointer;
}

#VideoPauseControl svg {
  width: 35px;
  opacity: 0.7;
}

.facebook-icon {
  content: "";
  display: block;
  width: 55px;
  height: 55px;
  margin: 0 auto;
  background-image: url("../Svgs/fb.svg");
  background-repeat: no-repeat;
  transition: all 0.5s ease;
  margin-top: 10px;
}

.facebook-icon:hover {
  opacity: 0.4;
}

.to-top {
  border-radius: 50%;
  background-color: #F2B231;
  width: 50px;
  height: 50px;
  padding: 2px 6px;
  margin: 0;
  color: #fff;
  opacity: 0.5;
  position: fixed;
  bottom: 10px;
  right: 40px;
  text-decoration: none;
  -moz-transition: opacity ease-in 0.2s;
  -o-transition: opacity ease-in 0.2s;
  -webkit-transition: opacity ease-in 0.2s;
  transition: opacity ease-in 0.2s;
  background-image: url("../Icons/arrow-up.svg");
  background-size: 30px 30px;
  background-position: center;
  background-repeat: no-repeat;
  text-indent: -9999px;
  z-index: 99;
}

.to-top:hover {
  opacity: 1;
  color: #FFF;
}

.floorplan-single {
  text-align: center;
}

.floorplan-single > a {
  float: initial !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.floorplan-page div {
  text-align: center !important;
}

.floorplan-page table {
  margin: 0 auto !important;
  margin-top: 20px !important;
}

.floorplan-page .typography p {
  padding-left: 50px;
  padding-right: 50px;
}

.typography h3 {
  font-size: 2.48832rem;
  line-height: 1.1em;
}

@media only screen and (max-width: 40em) {
  .typography h3 {
    font-size: 1.44rem;
    line-height: 1.1em;
  }
}

.typography div {
  font-size: 18px;
}

@media only screen and (min-width: 40.0625em) {
  .typography div {
    font-size: 22px;
  }
}

.beliggenhet-text {
  font-size: 18px;
}

@media only screen and (min-width: 40.0625em) {
  .beliggenhet-text {
    font-size: 22px;
  }
}

.news-section-wrapper {
  overflow: auto;
}

.news-image-link:hover {
  opacity: 0.85;
}

.news-front-page-item {
  padding-bottom: 20px;
}

@media only screen and (max-width: 40em) {
  .news-front-page-item {
    text-align: center;
  }
}

@media only screen and (min-width: 64.0625em) {
  .news-front-page-item {
    width: 31% !important;
  }
}

.portal-news-page-date-wrapper {
  position: absolute;
  text-transform: uppercase;
  background: #F2B231;
  width: 55px;
  height: 55px;
  color: white;
  padding: 5px;
  z-index: 1;
  text-align: center;
  left: 0%;
}

.news-image-link {
  width: 100%;
  transition: 300ms all ease;
}

.portal-news-page-header {
  padding-top: 6px;
  padding-bottom: 8px;
}

.portal-news-page-description {
  text-align: left !important;
}

.news-content-read-more {
  padding: 20px;
  font-size: 18px;
}

@media only screen and (min-width: 40.0625em) {
  .news-content-read-more {
    font-size: 22px;
  }
}

.news-folder-header {
  padding-top: 15px;
}

.page-folder-news-item {
  padding-bottom: 25px;
  padding-top: 25px;
}

@media only screen and (min-width: 40.0625em) {
  .page-folder-news-item {
    width: 100%;
  }
}

.img-fullwidth {
  width: 100%;
}

@media only screen and (min-width: 64.0625em) {
  .news-folder-content {
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.news-folder-main-news-date-wrapper-wrapper {
  position: relative;
}

.news-folder-main-news-date-wrapper {
  position: absolute;
  text-transform: uppercase;
  background: #F2B231;
  width: 55px;
  height: 55px;
  color: white;
  padding: 5px;
  z-index: 1;
  text-align: center;
  left: 20px;
  bottom: -2px;
}

.news-folder-sub-news-date-wrapper-wrapper {
  position: relative;
}

.news-folder-sub-news-date-wrapper {
  position: absolute;
  text-transform: uppercase;
  background: #F2B231;
  width: 55px;
  height: 55px;
  color: white;
  padding: 5px;
  z-index: 1;
  text-align: center;
  left: 20px;
  bottom: -2px;
}

.u-paging {
  text-align: center;
}

.u-paging .u-paging-li {
  display: inline-block;
}

.news-page-date-wrapper-wrapper {
  position: relative;
}

.news-page-date-wrapper {
  position: absolute;
  text-transform: uppercase;
  background: #F2B231;
  width: 55px;
  height: 55px;
  color: white;
  padding: 5px;
  z-index: 1;
  text-align: center;
  top: 20%;
  left: 0%;
}

.news-separator {
  margin-top: 35px;
  padding-bottom: 10px;
}

.news-main-image-wrapper {
  padding-bottom: 20px;
}

.news-page-news-header-title {
  text-align: center;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
}

.news-page-actual-news-header-title {
  text-align: center;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
}

.news-content {
  font-size: 18px;
}

@media only screen and (min-width: 40.0625em) {
  .news-content {
    font-size: 22px;
  }
}

.news-ingress {
  font-size: 22px !important;
}

@media only screen and (min-width: 40.0625em) {
  .news-ingress {
    font-size: 26px !important;
  }
}

.news-description {
  font-size: 18px;
}

@media only screen and (min-width: 40.0625em) {
  .news-description {
    font-size: 22px;
  }
}

.news-typography {
  font-size: 18px;
}

@media only screen and (min-width: 40.0625em) {
  .news-typography {
    font-size: 22px;
  }
}

.news-sub-news-wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
}

.read-more-news-archive {
  text-align: center;
  margin: 0 auto;
  padding-top: 30px;
}

@media only screen and (min-width: 40.0625em) {
  .news-page-folder-bottom {
    margin-top: 80px;
  }
}

footer .horizontal-list {
  padding-right: 20px;
}

footer a {
  color: #fff;
  text-decoration: underline;
}

.footer-section {
  margin-bottom: 20px;
}

.skip-link {
  position: absolute;
  top: 0;
  left: 0;
}

.skip-link__link {
  opacity: 0;
  pointer-events: none;
}

.skip-link__link:focus {
  opacity: 1;
  display: block;
  pointer-events: all;
}

.skip-link {
  position: absolute;
  top: 0;
  left: 0;
}

.skip-link__link {
  opacity: 0;
  pointer-events: none;
}

.skip-link__link:focus {
  opacity: 1;
  display: block;
  pointer-events: all;
}

@media only screen and (min-width: 1160px) {
  .stuck {
    position: fixed;
    top: 0;
    left: 0;
    transition: transform 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  }
}

/*
.menu-is-open {
    .stuck {
        position: static;
        top: auto; 
        left: auto;
    }

}
    */
.sticky-spacer {
  display: none;
  background: #212121;
  /*@media only screen and (min-width: 1160px) {
        float: left;
        width: 100%;
        display: block;
    }*/
}

.sticky-spacer.js-hidden {
  display: none;
}

.nav {
  padding-bottom: 15px;
}

@media only screen and (min-width: 64.0625em) {
  .fixed-menu .site-header .relative.nav-wrapper {
    top: 40px;
  }
  .fixed-menu .main-menu-wrapper {
    position: relative;
  }
  .ms-menu {
    transform: translateY(0);
    transition: transform 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
    position: relative;
    z-index: 500;
    background-color: #FFF;
  }
}

@media only screen and (min-width: 64.0625em) and (min-width: 64.0625em) {
  .ms-menu {
    transform: none;
  }
}

@media only screen and (min-width: 64.0625em) {
  .fixed-menu .ms-menu {
    position: fixed;
    bottom: 100%;
    left: 0;
    width: 100%;
    z-index: 500;
    transform: translateY(100%);
    transition: transform 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
  }
}

@media only screen and (min-width: 64.0625em) and (min-width: 64.0625em) {
  .fixed-menu .ms-menu {
    transform: none;
  }
}

@media only screen and (min-width: 64.0625em) {
  .secondary-nav, .primary-nav {
    opacity: 1;
    transform: translateY(0%);
    transition: all 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .fixed-menu .secondary-nav {
    display: none;
    transform: translateY(-100%);
  }
  .primary-nav {
    transition: all 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .fixed-menu .primary-nav {
    transform: translateY(-50%);
  }
}

@keyframes ANIMATE-MENU-IN {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.menu-button {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 54px;
  height: 54px;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  -webkit-transition: background .3s;
  transition: background .3s;
  display: inline-block;
  cursor: pointer;
  float: right;
  background-color: transparent !important;
}

.menu-button span {
  display: block;
  position: absolute;
  top: 26px;
  left: 10px;
  right: 10px;
  height: 3px;
  background: #ffffff;
  pointer-events: none;
}

.menu-button span::after, .menu-button span::before {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #ffffff;
  content: "";
}

.menu-button span::before {
  top: -10px;
}

.menu-button span::after {
  bottom: -10px;
}

.menu-button span::before {
  -webkit-transition-property: top,-webkit-transform;
  transition-property: top,transform;
}

.menu-button span::after, .menu-button span::before {
  -webkit-transition-duration: .3s,.3s;
  transition-duration: .3s,.3s;
  -webkit-transition-delay: .3s,0s;
  transition-delay: .3s,0s;
}

.menu-button span::after {
  -webkit-transition-property: bottom,-webkit-transform;
  transition-property: bottom,transform;
}

.menu-is-open .menu-button span {
  background: 0 0;
}

.menu-is-open .menu-button span::after, .menu-is-open .menu-button span::before {
  -webkit-transition-delay: 0s,.3s;
  transition-delay: 0s,.3s;
  background: #fff;
}

.menu-is-open .menu-button span::before {
  top: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background: #fff;
}

.menu-is-open .menu-button span::after {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menu-is-open .menu-button span::after {
  -webkit-transition-property: bottom,-webkit-transform;
  transition-property: bottom,transform;
}

.site-logo {
  width: 100%;
  z-index: 5;
}

.site-logo svg {
  max-width: 100%;
  height: 60px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.secondary-nav {
  margin-bottom: 10px;
}

@media only screen and (min-width: 1160px) {
  .primary-nav a {
    text-decoration: none;
    color: #000000;
    transition: color 300ms;
    font-size: 20px;
    font-weight: 600;
  }
  .primary-nav .active::after,
  .primary-nav li:focus::after,
  .primary-nav li:hover::after {
    height: 2px;
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    transform: translateY(0px);
  }
  .primary-nav li::after {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    background: black;
    content: '';
    opacity: 0;
    -webkit-transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
    -moz-transition: height 0.3s, opacity 0.3s, -moz-transform 0.3s;
    transition: height 0.3s, opacity 0.3s, transform 0.3s;
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  .primary-nav:hover {
    color: white;
    transition: all 500ms ease;
  }
  .nav {
    height: auto;
    display: block;
    visibility: visible;
    transition: all 500ms cubic-bezier(0, 0, 0.39, 1.1);
    padding-top: 15px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .nav::after {
    clear: both;
    content: "";
    display: table;
  }
  .ms-menu--is-open .nav {
    transform: translateY(-100%);
  }
  .nav .main-nav-item.expanded:hover .level2 {
    display: block;
    height: auto;
    max-height: 500px;
    padding: 10px;
  }
  .nav .level2 {
    transition: all 500ms ease;
    position: absolute;
    width: 100%;
    z-index: 999;
    background: #fff;
    text-align: left;
    list-style: square;
    top: 37px;
    min-width: 200px;
    padding: 0 10px;
    max-height: 0;
    overflow: hidden;
  }
  .nav .level2 .nav__item {
    padding: 0;
    display: block;
    font-size: 0.9em;
    padding: 7px 0;
  }
  .nav .level2 .nav__item a:hover {
    color: #6d6e71;
  }
}

@media only screen and (min-width: 1160px) and (min-width: 64.0625em) {
  .nav {
    transition: none;
  }
  .ms-menu--is-open .nav {
    transform: none;
  }
}

@media only screen and (min-width: 1160px) {
  .nav__list {
    display: block;
  }
}

@media only screen and (min-width: 1160px) and (min-width: 64.0625em) {
  .nav__list {
    display: block;
    margin: 0 auto;
    text-align: center;
  }
}

@media only screen and (min-width: 1160px) {
  .nav__item {
    display: block;
    transition: all 500ms ease;
    transform: translateY(100px);
    opacity: 0;
    margin: 0 19px;
    padding: 10px 0;
    line-height: 0.85em;
  }
  .nav__item:last-child {
    border-right: 0;
  }
}

@media only screen and (min-width: 1160px) and (min-width: 64.0625em) {
  .nav__item {
    transform: translate(0);
    opacity: 1;
    transition: none;
    display: inline-block;
    position: relative;
    z-index: 999;
  }
}

.menu-button-wrapper {
  opacity: 0;
  position: absolute;
  right: 23px;
  z-index: 999;
  margin-top: 20px;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
}

@media only screen and (max-width: 1159px) {
  .ms-menu {
    position: static;
  }
  .primary-nav .nav-logo {
    background-image: url("../Svgs/logo.svg");
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    right: 50%;
    margin-left: -55px;
    width: 110px;
    height: 60px;
    top: auto;
    bottom: -80px;
  }
  .site-logo {
    width: 80%;
    z-index: 5;
    float: left;
  }
  .site-logo svg {
    max-width: 200px;
    height: 65px;
    margin-top: 15px;
    margin-bottom: 5px;
    margin-left: 15px;
  }
  .menu-button-wrapper {
    opacity: 1;
  }
  .header-section {
    position: fixed;
    right: 100%;
    top: 0px;
    padding-bottom: 140px;
    width: 100%;
    z-index: 99;
    background-color: #EA5D0B;
    background-size: 400% 400%;
    transition: all 600ms cubic-bezier(0.63, 0.18, 0.2, 0.93);
  }
  .secondary-nav {
    padding-top: 50px;
  }
  .nav-wrapper {
    height: 100%;
  }
  span.right-logo {
    display: none;
  }
  span.right-logo img {
    max-width: 110px;
    width: 100%;
  }
  .nav {
    width: 100%;
    background-color: inherit;
    float: left;
    color: #000000;
    text-align: center;
  }
  .nav__item {
    opacity: 1;
  }
  .nav__item a {
    color: #fff;
  }
  .nav__item a:hover {
    color: #6d6e71;
  }
  .top-navigation {
    padding: 20px;
  }
  .top-navigation ul li {
    padding: 5px 0;
    font-size: 1em;
  }
  .header-section .menu li a {
    text-decoration: none;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
  }
  .header-section .menu li a:hover {
    text-decoration: none;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
  }
  .toggle-submenu {
    height: 21px;
    width: 25px;
    display: inline-block;
    vertical-align: top;
    margin-left: 12px;
    position: absolute;
    right: 1em;
    cursor: pointer;
  }
  .toggle-submenu:after, .toggle-submenu:before {
    text-align: center;
    height: 3px;
    background-color: #EA5D0B;
    width: 100%;
    font-size: 30px;
    content: "";
    display: inline-block;
    vertical-align: top;
    transition: all 300ms ease;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .toggle-submenu:before {
    transform: translateY(-50%) rotate(90deg);
  }
  .toggle-submenu.nav__item--show-children:before {
    transform: translateY(-50%) rotate(0deg);
  }
  .nav__item--has-children {
    max-height: 0;
    transition: all 300ms ease;
    overflow: hidden;
  }
  .nav__item--show-children ~ .nav__item--has-children {
    max-height: 600px;
  }
  .site-logo {
    width: 100%;
    z-index: 5;
  }
  .menu-hr {
    display: block;
  }
  .primary-nav {
    padding: 20px;
  }
  .primary-nav ul li {
    padding: 5px 0;
    text-transform: uppercase;
    font-size: 1.2em;
  }
  .nav__item:nth-child(4) {
    margin-right: 0px !important;
  }
  .nav__item:nth-child(5) {
    margin-left: 0px !important;
  }
  .nav-logo {
    margin-top: 30px !important;
  }
  .header-section {
    position: absolute;
    top: 0px;
  }
  .menu-is-open .header-section {
    position: absolute;
    color: black;
    background: #EA5D0B;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
    padding-top: 80px;
  }
  .menu-is-open .header-section a {
    color: black;
  }
}

.menu-btn {
  float: left;
  width: 20%;
  margin-left: 2%;
}

/*.nav__item:nth-child(3) {
    margin-right: 150px;
}

.nav__item:nth-child(4) {
    
}*/
.header-section {
  /*box-shadow: 0 0 6px rgba(0,0,0,0.4);*/
  background: #EA5D0B;
}

.header-section.stuck {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  background: #EA5D0B;
}

.nav-logo {
  background-image: url("../Svgs/logo.svg");
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  width: 144px;
  height: 40px;
  top: 10px;
}

/**
 * @name Forms
 * @order 3
 */
/**
 * @name Input fields
 * @state :focus - Focussed state
 * @state :hover - Hover state
 * @state .error - Error state
 * @markup
 * <form><label>Label:</label> <input type="text" class="{{{escaped}}}"></form>
 */
textarea, input:not([type='submit']):not([type='button']):not([type='checkbox']):not([type='radio']):not([type='reset']) {
  font-size: 18px;
  font-family: "Futura", Calibri, Candara, Segoe UI, Optima, Arial, sans-serif;
  padding: 13px;
  background-color: white;
  border-width: 2px;
  border-color: #a7a7a7;
  border-radius: 0px;
  border-style: solid;
  color: #000000;
  margin-bottom: 15px;
  width: 100%;
  transition: border-color 200ms;
}

textarea:focus, textarea:hover, input:not([type='submit']):not([type='button']):not([type='checkbox']):not([type='radio']):not([type='reset']):focus, input:not([type='submit']):not([type='button']):not([type='checkbox']):not([type='radio']):not([type='reset']):hover {
  outline: 0;
  border-color: #EA5D0B;
  background-color: white;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input:not([type='checkbox']) {
  -webkit-appearance: none;
}

label.FieldLabelTop, .formTitle {
  display: none;
}

input[type='checkbox'] {
  margin-right: 5px !important;
}

/* reset */
.photoMosaic,
.photoMosaic img, .photoMosaic div, .photoMosaic span,
.photoMosaic a:link, .photoMosaic a:visited, .photoMosaic a:active, .photoMosaic a:hover {
  background: none;
  outline: none;
  border: 0;
  height: auto;
  width: auto;
  max-height: none !important;
  max-width: none !important;
  min-height: 0 !important;
  min-width: 0 !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  list-style: none;
  margin: 0;
  padding: 0;
  position: static;
  float: none;
  visibility: visible;
  overflow: visible;
  color: #000000;
  letter-spacing: normal;
  line-height: 120%;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  text-transform: none;
  vertical-align: baseline;
  white-space: normal;
  word-spacing: normal;
}

/* selective reset */
.photoMosaic img, .photoMosaic a:link, .photoMosaic a:visited, .photoMosaic a:active, .photoMosaic a:hover {
  margin: 0 !important;
  padding: 0 !important;
}

/* base */
.photoMosaic {
  position: relative;
  overflow: hidden;
}

.photoMosaic span,
.photoMosaic a:link,
.photoMosaic a:visited,
.photoMosaic a:active,
.photoMosaic a:hover {
  display: block;
  overflow: hidden;
  position: absolute;
}

.photoMosaic img {
  display: block;
  position: relative;
  top: 0px;
  opacity: 1;
}

.photoMosaic a:hover img {
  opacity: 0.8;
}

.photoMosaic img.thumbnail,
.photoMosaic img.medium,
.photoMosaic img.large {
  position: absolute;
  opacity: 0;
}

/* loading */
.photoMosaic .photoMosaicLoading {
  background: transparent url("/../../../../Klei/Maxbygget/Skin/Icons/PhotoMosaic/spinner-arrows.gif") 0px 0px no-repeat;
  padding: 0 0 0 20px;
  line-height: 20px;
}

.photoMosaic.loading .photomosaic-spinner {
  background: #efefef url("../../../../Klei/Maxbygget/Skin/Icons/PhotoMosaic/spinner-snake.gif") 50% 50% no-repeat;
}

.photoMosaic.loading .photomosaic-item.loaded .photomosaic-spinner {
  display: none;
}

.PM_preloadify {
  visibility: hidden;
  position: absolute;
  bottom: 5px;
  right: 10px;
}

.PM_js .photoMosaicTarget .gallery {
  display: none;
}

/* overflow:hidden; on a container kills 3d */
/* base */
.PM_csstransforms.PM_csstransitions .photoMosaic .photomosaic-item,
.PM_csstransforms.PM_csstransitions .photoMosaic img {
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: visible;
  -moz-backface-visibility: visible;
  -ms-backface-visibility: visible;
  -o-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-transform: 1.2rem;
  -moz-transform: 1.2rem;
  -ms-transform: 1.2rem;
  -o-transform: 1.2rem;
  transform: 1.2rem;
}

/* The .loaded is added after all of the images have loaded */
.PM_csstransforms.PM_csstransitions .photoMosaic.resize-transition-none .photomosaic-item,
.PM_csstransforms.PM_csstransitions .photoMosaic.loading-transition-none img,
.PM_csstransforms.PM_csstransitions .photoMosaic.resize-transition-none .loaded img {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}

.PM_csstransforms.PM_csstransitions .photoMosaic.transition-custom .loading img,
.PM_csstransforms.PM_csstransitions .photoMosaic.loading-transition-custom .loading img {
  /* good for example
        opacity:0;
        -webkit-transform: rotate( -5deg ) scale( 0.95 );
           -moz-transform: rotate( -5deg ) scale( 0.95 );
            -ms-transform: rotate( -5deg ) scale( 0.95 );
             -o-transform: rotate( -5deg ) scale( 0.95 );
                transform: rotate( -5deg ) scale( 0.95 );
    */
}

.PM_csstransforms.PM_csstransitions .photoMosaic.transition-fade .loading img,
.PM_csstransforms.PM_csstransitions .photoMosaic.loading-transition-fade .loading img {
  opacity: 0;
}

/*.PM_csstransforms.PM_csstransitions .photoMosaic.transition-scale-up .loading img,
.PM_csstransforms.PM_csstransitions .photoMosaic.loading-transition-scale-up .loading img {
    opacity: 0;
       -moz-transform: scale( 0.95 );
        -ms-transform: scale( 0.95 );
         -o-transform: scale( 0.95 );
            transform: scale( 0.95 );
}

.PM_csstransforms.PM_csstransitions .photoMosaic.transition-scale-down .loading img,
.PM_csstransforms.PM_csstransitions .photoMosaic.loading-transition-scale-down .loading img {
    opacity: 0;
    -webkit-transform: scale( 1.15 );
       -moz-transform: scale( 1.15 );
        -ms-transform: scale( 1.15 );
         -o-transform: scale( 1.15 );
            transform: scale( 1.15 );
}*/
.PM_csstransforms.PM_csstransitions .photoMosaic.transition-slide-up .loading img,
.PM_csstransforms.PM_csstransitions .photoMosaic.loading-transition-slide-up .loading img {
  opacity: 0;
  -webkit-transform: translateY(5%);
  -moz-transform: translateY(5%);
  -ms-transform: translateY(5%);
  -o-transform: translateY(5%);
  transform: translateY(5%);
}

.PM_csstransforms.PM_csstransitions .photoMosaic.transition-slide-down .loading img,
.PM_csstransforms.PM_csstransitions .photoMosaic.loading-transition-slide-down .loading img {
  opacity: 0;
  -webkit-transform: translateY(-5%);
  -moz-transform: translateY(-5%);
  -ms-transform: translateY(-5%);
  -o-transform: translateY(-5%);
  transform: translateY(-5%);
}

.PM_csstransforms.PM_csstransitions .photoMosaic.transition-slide-left .loading img,
.PM_csstransforms.PM_csstransitions .photoMosaic.loading-transition-slide-left .loading img {
  opacity: 0;
  -webkit-transform: translateX(5%);
  -moz-transform: translateX(5%);
  -ms-transform: translateX(5%);
  -o-transform: translateX(5%);
  transform: translateX(5%);
}

.PM_csstransforms.PM_csstransitions .photoMosaic.transition-slide-right .loading img,
.PM_csstransforms.PM_csstransitions .photoMosaic.loading-transition-slide-right .loading img {
  opacity: 0;
  -webkit-transform: translateX(-5%);
  -moz-transform: translateX(-5%);
  -ms-transform: translateX(-5%);
  -o-transform: translateX(-5%);
  transform: translateX(-5%);
}

/* START PrettyPhoto */
/* ------------------------------------------------------------------------
	This you can edit.
------------------------------------------------------------------------- */
/* ---------------------------------- 
		Default Theme
	----------------------------------- */
div.pp_default .pp_top,
div.pp_default .pp_top .pp_middle,
div.pp_default .pp_top .pp_left,
div.pp_default .pp_top .pp_right,
div.pp_default .pp_bottom,
div.pp_default .pp_bottom .pp_left,
div.pp_default .pp_bottom .pp_middle,
div.pp_default .pp_bottom .pp_right {
  height: 13px;
}

/*div.pp_default .pp_top .pp_left { background: url(../prettyphoto/default/sprite.png) -78px -93px no-repeat; } /* Top left corner */
div.pp_default .pp_top .pp_left {
  background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite.png) -78px -93px no-repeat;
}

/* Top left corner */
div.pp_default .pp_top .pp_middle {
  background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite_x.png) top left repeat-x;
}

/* Top pattern/color */
div.pp_default .pp_top .pp_right {
  background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite.png) -112px -93px no-repeat;
}

/* Top right corner */
div.pp_default .pp_content .ppt {
  color: #f8f8f8;
}

div.pp_default .pp_content_container .pp_left {
  background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite_y.png) -7px 0 repeat-y;
  padding-left: 13px;
}

div.pp_default .pp_content_container .pp_right {
  background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite_y.png) top right repeat-y;
  padding-right: 13px;
}

div.pp_default .pp_content {
  background-color: #fff;
}

/* Content background */
div.pp_default .pp_next:hover {
  background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite_next.png) center right no-repeat;
  cursor: pointer;
}

/* Next button */
div.pp_default .pp_previous:hover {
  background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite_prev.png) center left no-repeat;
  cursor: pointer;
}

/* Previous button */
div.pp_default .pp_expand {
  background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite.png) 0 -29px no-repeat;
  cursor: pointer;
  width: 28px;
  height: 28px;
}

/* Expand button */
div.pp_default .pp_expand:hover {
  background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite.png) 0 -56px no-repeat;
  cursor: pointer;
}

/* Expand button hover */
div.pp_default .pp_contract {
  background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite.png) 0 -84px no-repeat;
  cursor: pointer;
  width: 28px;
  height: 28px;
}

/* Contract button */
div.pp_default .pp_contract:hover {
  background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite.png) 0 -113px no-repeat;
  cursor: pointer;
}

/* Contract button hover */
div.pp_default .pp_close {
  width: 30px;
  height: 30px;
  background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite.png) 2px 1px no-repeat;
  cursor: pointer;
}

/* Close button */
div.pp_default #pp_full_res .pp_inline {
  color: #000;
}

div.pp_default .pp_gallery ul li a {
  background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/default_thumb.png) center center #f8f8f8;
  border: 1px solid #aaa;
}

div.pp_default .pp_gallery ul li a:hover,
div.pp_default .pp_gallery ul li.selected a {
  border-color: #fff;
}

div.pp_default .pp_social {
  margin-top: 7px;
}

div.pp_default .pp_gallery a.pp_arrow_previous,
div.pp_default .pp_gallery a.pp_arrow_next {
  position: static;
  left: auto;
}

div.pp_default .pp_nav .pp_play,
div.pp_default .pp_nav .pp_pause {
  background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite.png) -51px 1px no-repeat;
  height: 30px;
  width: 30px;
}

div.pp_default .pp_nav .pp_pause {
  background-position: -51px -29px;
}

div.pp_default .pp_details {
  position: relative;
}

div.pp_default a.pp_arrow_previous,
div.pp_default a.pp_arrow_next {
  background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite.png) -31px -3px no-repeat;
  height: 20px;
  margin: 4px 0 0 0;
  width: 20px;
}

div.pp_default a.pp_arrow_next {
  left: 52px;
  background-position: -82px -3px;
}

/* The next arrow in the bottom nav */
div.pp_default .pp_content_container .pp_details {
  margin-top: 5px;
}

div.pp_default .pp_nav {
  clear: none;
  height: 30px;
  width: 110px;
  position: relative;
}

div.pp_default .pp_nav .currentTextHolder {
  font-family: Georgia;
  font-style: italic;
  color: #999;
  font-size: 11px;
  left: 75px;
  line-height: 25px;
  margin: 0;
  padding: 0 8px 0 8px;
}

div.pp_default .pp_close:hover, div.pp_default .pp_nav .pp_play:hover, div.pp_default .pp_nav .pp_pause:hover, div.pp_default .pp_arrow_next:hover, div.pp_default .pp_arrow_previous:hover {
  opacity: 0.7;
}

div.pp_default .pp_description {
  font-size: 11px;
  font-weight: bold;
  line-height: 14px;
  margin: 5px 50px 5px 0;
}

div.pp_default .pp_bottom .pp_left {
  background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite.png) -78px -127px no-repeat;
}

/* Bottom left corner */
div.pp_default .pp_bottom .pp_middle {
  background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite_x.png) bottom left repeat-x;
}

/* Bottom pattern/color */
div.pp_default .pp_bottom .pp_right {
  background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/sprite.png) -112px -127px no-repeat;
}

/* Bottom right corner */
div.pp_default .pp_loaderIcon {
  background: url(../../Skin/Icons/PhotoMosaic/Prettyphoto/loader.gif) center center no-repeat;
}

/* Loader icon */
/* ------------------------------------------------------------------------
	DO NOT CHANGE
------------------------------------------------------------------------- */
div.pp_pic_holder a:focus {
  outline: none;
}

div.pp_overlay {
  /*position: absolute;*/
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  display: none;
  width: 100%;
  z-index: 9500;
}

div.pp_pic_holder {
  display: none;
  position: absolute;
  width: 100px;
  z-index: 10000;
}

.pp_top {
  height: 20px;
  position: relative;
}

* html .pp_top {
  padding: 0 20px;
}

.pp_top .pp_left {
  height: 20px;
  left: 0;
  position: absolute;
  width: 20px;
}

.pp_top .pp_middle {
  height: 20px;
  left: 20px;
  position: absolute;
  right: 20px;
}

* html .pp_top .pp_middle {
  left: 0;
  position: static;
}

.pp_top .pp_right {
  height: 20px;
  left: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.pp_content {
  height: 40px;
  min-width: 40px;
}

* html .pp_content {
  width: 40px;
}

.pp_fade {
  display: none;
}

.pp_content_container {
  position: relative;
  text-align: left;
  width: 100%;
}

.pp_content_container .pp_left {
  padding-left: 20px;
}

.pp_content_container .pp_right {
  padding-right: 20px;
}

.pp_content_container .pp_details {
  float: left;
  margin: 10px 0 2px 0;
}

.pp_description {
  display: none;
  margin: 0;
}

.pp_social {
  float: left;
  margin: 0;
}

.pp_social .facebook {
  float: left;
  margin-left: 5px;
  width: 55px;
  overflow: hidden;
}

.pp_social .twitter {
  float: left;
}

.pp_nav {
  clear: right;
  float: left;
  margin: 3px 10px 0 0;
}

.pp_nav p {
  float: left;
  margin: 2px 4px;
  white-space: nowrap;
}

.pp_nav .pp_play,
.pp_nav .pp_pause {
  float: left;
  margin-right: 4px;
  text-indent: -10000px;
}

a.pp_arrow_previous,
a.pp_arrow_next {
  display: block;
  float: left;
  height: 15px;
  margin-top: 3px;
  overflow: hidden;
  text-indent: -10000px;
  width: 14px;
}

.pp_hoverContainer {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2000;
}

.pp_gallery {
  display: none;
  left: 50%;
  margin-top: -50px;
  position: absolute;
  z-index: 10000;
}

.pp_gallery div {
  float: left;
  overflow: hidden;
  position: relative;
}

.pp_gallery ul {
  float: left;
  height: 35px;
  margin: 0 0 0 5px;
  padding: 0;
  position: relative;
  white-space: nowrap;
}

.pp_gallery ul a {
  border: 1px #000 solid;
  border: 1px rgba(0, 0, 0, 0.5) solid;
  display: block;
  float: left;
  height: 33px;
  overflow: hidden;
}

.pp_gallery ul a:hover,
.pp_gallery li.selected a {
  border-color: #fff;
}

.pp_gallery ul a img {
  border: 0;
}

.pp_gallery li {
  display: block;
  float: left;
  margin: 0 5px 0 0;
  padding: 0;
}

.pp_gallery li.default a {
  background: url(../prettyphoto/facebook/default_thumbnail.gif) 0 0 no-repeat;
  display: block;
  height: 33px;
  width: 50px;
}

.pp_gallery li.default a img {
  display: none;
}

.pp_gallery .pp_arrow_previous,
.pp_gallery .pp_arrow_next {
  margin-top: 7px !important;
}

a.pp_next {
  background: url(../prettyphoto/light_rounded/btnNext.png) 10000px 10000px no-repeat;
  display: block;
  float: right;
  height: 100%;
  text-indent: -10000px;
  width: 49%;
}

a.pp_previous {
  background: url(../../prettyphoto/light_rounded/btnNext.png) 10000px 10000px no-repeat;
  display: block;
  float: left;
  height: 100%;
  text-indent: -10000px;
  width: 49%;
}

a.pp_expand,
a.pp_contract {
  cursor: pointer;
  display: none;
  height: 20px;
  position: absolute;
  right: 30px;
  text-indent: -10000px;
  top: 10px;
  width: 20px;
  z-index: 20000;
}

a.pp_close {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  line-height: 22px;
  text-indent: -10000px;
}

.pp_bottom {
  height: 20px;
  position: relative;
}

* html .pp_bottom {
  padding: 0 20px;
}

.pp_bottom .pp_left {
  height: 20px;
  left: 0;
  position: absolute;
  width: 20px;
}

.pp_bottom .pp_middle {
  height: 20px;
  left: 20px;
  position: absolute;
  right: 20px;
}

* html .pp_bottom .pp_middle {
  left: 0;
  position: static;
}

.pp_bottom .pp_right {
  height: 20px;
  left: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.pp_loaderIcon {
  display: block;
  height: 24px;
  left: 50%;
  margin: -12px 0 0 -12px;
  position: absolute;
  top: 50%;
  width: 24px;
}

#pp_full_res {
  line-height: 1 !important;
}

#pp_full_res .pp_inline {
  text-align: left;
}

#pp_full_res .pp_inline p {
  margin: 0 0 15px 0;
}

div.ppt {
  color: #fff;
  display: none;
  /*font-size: 17px;*/
  font-size: 0px;
  /*margin: 0 0 5px 15px;*/
  margin: 0;
  z-index: 9999;
}

/*END PrettyPhoto*/
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

@media only screen and (min-width: 64.0625em) and (max-width: 90em) {
  .page-wrapper {
    padding-top: 0;
  }
}

@media only screen and (min-width: 40.0625em) {
  .page-wrapper {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
  }
  .page-wrapper::after {
    clear: both;
    content: "";
    display: table;
  }
}

ul {
  list-style: circle;
}

@media only screen and (min-width: 40.0625em) {
  .module {
    max-width: 82em;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
  }
  .module::after {
    clear: both;
    content: "";
    display: table;
  }
}

.svg-icon {
  width: 100px;
  height: 100px;
  fill: tomato;
}

.menu-button {
  display: inline-block;
}
